import axios from "axios";
import { getTokens } from ".";
import { getRefreshToken, COOKIE_EXPIRY_DAYS } from "master";
import { setCookie, getCookie, removeCookie } from "utils";
import * as Sentry from "@sentry/react";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "api/",
  headers: {
    "Content-Type": "application/json",
    source: "StudentWeb",
  },
});

Axios.interceptors.request.use(
  (config) => {
    const tokens = getTokens();

    if (tokens?.token) {
      config.headers["Authorization"] = "Bearer " + tokens?.token;
    } else {
      config.headers["Authorization"] = null;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let requestsQueue = [];
let isRefreshing = false;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = { ...error.config };

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const token = await refreshToken();
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          await retryQueuedRequests();

          return Axios({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
            },
            sent: true,
          });
        } catch (error) {
          sessionTimeOutEvent();
          return Promise.reject(error);
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise((resolve, reject) => {
        requestsQueue.push({
          resolve,
          reject,
          originalRequest,
        });
      });
    }

    const skipStatus = [400, 401];

    if (originalRequest?.skipSentry) {
      skipStatus.push(...originalRequest.skipSentry);
    }
    if (!skipStatus.includes(error?.response?.status)) {
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    const localUser = JSON.parse(getCookie("_stu_user_data"));

    if (!localUser || !localUser?.refreshToken || !localUser?.token) {
      sessionTimeOutEvent();
    }

    const tokenPayload = {
      token: localUser?.token,
      refreshToken: localUser?.refreshToken,
    };

    const response = await getRefreshToken(tokenPayload);

    if (!response || !response?.token || !response?.refreshToken) {
      sessionTimeOutEvent();
    }

    const updatedData = {
      ...localUser,
      refreshToken: response?.refreshToken,
      token: response?.token,
    };

    const isRemember = JSON.parse(localStorage.getItem("_stu_is_remember"));
    if (isRemember) {
      setCookie(
        "_stu_user_data",
        JSON.stringify(updatedData),
        COOKIE_EXPIRY_DAYS
      );
    } else {
      setCookie("_stu_user_data", JSON.stringify(updatedData));
    }

    return response?.token;
  } catch (error) {
    sessionTimeOutEvent();
    throw error;
  }
};

const retryQueuedRequests = async () => {
  const pendingRequests = [...requestsQueue];
  requestsQueue = [];

  pendingRequests.forEach(({ originalRequest: config, resolve, reject }) => {
    const { headers, ...rest } = config;

    Axios.request({
      ...rest,
      headers: { ...headers },
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

const sessionTimeOutEvent = () => {
  removeCookie("_stu_user_data");
  window.location.href = "/session-expired";
};
