import { GET_COURSES_BY_ID, getCoursesById } from "..";
import { useQuery } from "react-query";

export const useCourseInfoQuery = ({ courseId, programId = "" }) => {
  return useQuery(
    [GET_COURSES_BY_ID, programId, courseId],
    () => getCoursesById(programId, courseId),
    {
      enabled: courseId > 0 && programId !== "",
      refetchOnWindowFocus: true,
    }
  );
};
