import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";

export const useCountdown = ({ validTill, currentTime }) => {
  const countDownDate = new Date(validTill).getTime();
  const countDownCurrentTime = new Date(currentTime).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - countDownCurrentTime
  );
  const [timer, setTimer] = useImmer({
    hours: null,
    minutes: null,
    seconds: null,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((draft) => {
        if (isNaN(draft) || draft <= 0) {
          draft = countDownDate - countDownCurrentTime;
        } else {
          draft = draft - 1000;
        }
        return draft;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  useEffect(() => {
    if (countDown !== 0) {
      const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
      setTimer((draft) => {
        draft = { hours, minutes, seconds };
        return draft;
      });
    }
    if (countDown <= 0) {
      setTimer((draft) => {
        draft = { hours: 0, minutes: 0, seconds: 0 };
        return draft;
      });
    }
  }, [countDown]);

  return { timer };
};
