import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";

export const SlotConfirmModal = ({
  isOpen,
  onCancel,
  onConfirm,
  data,
  ...rest
}) => {
  const { scheduledDate, startAtStr, endAtStr } = data;

  return (
    <Modal isOpen={isOpen} size={"md"} {...rest} className="assessment-modal">
      <ModalHeader toggle={onCancel}>Schedule Assessment</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="assessment-modal-layout">
              <div className="modal-md-icon calendar-icon"></div>
              <h3>
                {moment(scheduledDate, "DD-MMM-YYYY").format("MMMM DD YYYY")}
                <br />
                {startAtStr} - {endAtStr}
              </h3>
              <p>Please confirm your assessment date and time</p>
              <p className="modal-p-sm">
                Please note that you are not allowed to change the date and time
                once confirmed.
              </p>
              <div className="modal-btn-group">
                <button
                  onClick={onCancel}
                  className="assessment-md-btn me-2 tile-btn-white"
                >
                  Back
                </button>
                <button
                  onClick={onConfirm}
                  className="assessment-md-btn ms-2 tile-btn-blue"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
