import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getTokens } from "utils";

export const HeaderMain = ({ children }) => {
  const Nergy360Logo = process.env.REACT_APP_MAIN_LOGO_BLUE;

  const location = useLocation();
  const tokens = getTokens();

  const isAuthenticated = tokens && tokens?.token;
  const homeUrl = isAuthenticated ? "/home" : "/";

  const isQuestionnairePages =
    location.pathname === "/home/assessment/start" ||
    location.pathname === "/home/assessment/review";

  const mainLogo = isQuestionnairePages ? (
    <span className="navbar-brand">
      <img
        className="header-main-logo"
        src={Nergy360Logo}
        alt="nergy 360 logo"
      />
    </span>
  ) : (
    <Link to={homeUrl} className="navbar-brand">
      <img
        className="header-main-logo"
        src={Nergy360Logo}
        alt="nergy 360 logo"
      />
    </Link>
  );

  return (
    <nav className="navbar navbar-expand-lg border-bottom">
      <div className="container-md">
        <div className="col-12 row">
          <div className="d-flex justify-content-between align-items-center">
            {mainLogo}
            <div className="header-right-block">{children}</div>
          </div>
        </div>
      </div>
    </nav>
  );
};
