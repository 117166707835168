import React, { useCallback } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useQuestionareContext } from "master";
import { useNavigate } from "react-router-dom";

export const TimeoutModal = ({ timeTaken, ...rest }) => {
  const questionnaireContext = useQuestionareContext();
  const { isModalOpen, setIsModalOpen } = questionnaireContext;

  const navigate = useNavigate();

  const onSubmit = useCallback(() => {
    setIsModalOpen((draft) => {
      draft.isOpen = false;
      draft.type = "";
      return draft;
    });
    navigate("/home/assessment/review", { replace: true });
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen.type === "timeOut" && isModalOpen.isOpen}
      size={"md"}
      {...rest}
      style={{ width: "470px" }}
    >
      <ModalHeader>Assessment Timeout</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="assessment-modal-layout timeout-modal">
              <div className="modal-md-icon check-timer-icon"></div>
              <h3>{`Time Taken : ${timeTaken}m`} </h3>
              <p>Your Section being timeout.</p>
              <div className="modal-btn-group">
                <button
                  onClick={onSubmit}
                  className="assessment-md-btn px-5 tile-btn-blue"
                >
                  Review/Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
