import React from "react";
import ReactPlayer from "react-player";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

export const VideoPlayerModal = ({ isOpen, onCancel, title, url }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      size="lg"
      centered={true}
      fullscreen="lg"
    >
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <ReactPlayer
          width="100%"
          height="350px"
          controls
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
                controlsList: "nodownload",
              },
            },
          }}
          url={url}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
