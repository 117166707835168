import { Axios } from "utils";

export const getCertificates = async (studentBatchId) => {
  const res = await Axios.get(
    `/v2/portal/certificates/batches/${studentBatchId}`
  );
  return res.data;
};

export const getAllCertificates = async () => {
  const res = await Axios.get(`/v2/portal/programs/generated-certificates`);
  return res.data;
};

export const getCertificateById = async (studentCertificateId) => {
  const res = await Axios.get(
    `/Certificates/CertrtificateViewHtml/${studentCertificateId}`
  );
  return res.data;
};
