import React from "react";
import { Link } from "react-router-dom";
import { IndividualNotification } from "..";

export const NotificationItem = ({ data, url }) => {
  return (
    <>
      {url ? (
        <Link
          to={url}
          className="side-notification-item p-2 mb-2 d-block text-dark"
        >
          <IndividualNotification data={data} trim={true} />
        </Link>
      ) : (
        <div className="notification-item text-start rounded-1 d-block mb-3 p-3">
          <IndividualNotification data={data} />
        </div>
      )}
    </>
  );
};
