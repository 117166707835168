import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuestionnaire, NetworkStatusMessage } from "..";
import { HelpModal, TimeoutModal, InstructionsModal } from "modules";
import { useImmer } from "use-immer";

export const QuestionnaireContext = React.createContext();

export const QuestionnaireScope = (props) => {
  const location = useLocation();
  const [isNetworkMessage, setIsNetworkMessage] = useImmer(false);

  const isQuestionnaire =
    location.pathname === "/home/assessment/start" ||
    location.pathname === "/home/assessment/review";

  const allQuestionsPages =
    isQuestionnaire || location.pathname === "/home/assessment/result";

  useEffect(() => {
    const disableRightClick = (e) => e.preventDefault();

    if (allQuestionsPages && isNetworkMessage) {
      window.addEventListener("contextmenu", disableRightClick);
    }
    return () => window.removeEventListener("contextmenu", disableRightClick);
  }, [allQuestionsPages, isNetworkMessage]);

  const {
    isModalOpen,
    setIsModalOpen,
    instruction,
    help,
    onToggleInstruction,
    onToggleHelp,
    questionnaire,
    isOnline,
    ...rest
  } = useQuestionnaire();

  useEffect(() => {
    if (isOnline) {
      setTimeout(() => {
        setIsNetworkMessage(false);
      }, 3000);
    } else {
      setIsNetworkMessage(true);
    }
  }, [isOnline]);

  const onStartAssessment = () => {
    window.open("/home/assessment/start", "_blank");
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        instruction,
        help,
        onToggleInstruction,
        onToggleHelp,
        questionnaire,
        isOnline,
        ...rest,
      }}
    >
      {allQuestionsPages && isNetworkMessage && (
        <NetworkStatusMessage isOnline={isOnline} />
      )}
      {props.children}
      {isModalOpen.isOpen && isModalOpen.type === "help" && (
        <HelpModal
          isOpen={isModalOpen.isOpen && isModalOpen.type === "help"}
          onCancel={() => onToggleHelp()}
          data={help}
        />
      )}
      {isModalOpen.isOpen && isModalOpen.type === "instruction" && (
        <InstructionsModal
          isOpen={isModalOpen.isOpen && isModalOpen.type === "instruction"}
          onCancel={() => {
            onToggleInstruction(null, "header");
          }}
          onSubmit={() => {
            onToggleInstruction(null, "header");
            onStartAssessment();
          }}
          data={instruction}
          actionFrom={isModalOpen.from}
        />
      )}
      {isModalOpen.type === "timeOut" &&
        isModalOpen.isOpen &&
        isQuestionnaire && <TimeoutModal timeTaken={questionnaire?.maxTime} />}
    </QuestionnaireContext.Provider>
  );
};
