import React, { useEffect, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { getMyPrograms, acceptTerms, activateBatch } from "..";
import { errorFormatter } from "shared";
import { GET_NOTIFICATIONS } from "modules";

const GET_MY_PROGRAMS = "GET_MY_PROGRAMS";

const initialTermsState = {
  isOpen: false,
  content: "",
  title: "",
};

export const useProgram = ({ load = false }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [termsModal, setTermsModal] = useImmer(initialTermsState);
  const [program, setProgram] = useImmer(null);

  const myProgramsQuery = useQuery(GET_MY_PROGRAMS, getMyPrograms, {
    enabled: load,
    staleTime: Infinity,
  });

  const acceptTerm = useMutation(acceptTerms, {
    onError: (e) => {
      errorFormatter(e);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MY_PROGRAMS]);
      queryClient.invalidateQueries([GET_NOTIFICATIONS]);
    },
    onSettled: () => {
      onToggleModal();
    },
  });

  const activateBatches = useMutation(activateBatch, {
    onError: (e) => {
      errorFormatter(e);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MY_PROGRAMS]);
    },
  });

  useEffect(() => {
    if (myProgramsQuery.data && myProgramsQuery.data.length > 0) {
      const localStorageProgram = JSON.parse(
        localStorage.getItem("selectedProgram")
      );
      if (localStorageProgram) {
        const selectedProgram = myProgramsQuery.data.find(
          (p) => p.id === localStorageProgram.id
        );
        if (selectedProgram) {
          setProgram(selectedProgram);
          localStorage.setItem(
            "selectedProgram",
            JSON.stringify(selectedProgram)
          );
        } else {
          setProgram(myProgramsQuery.data[0]);
          localStorage.setItem(
            "selectedProgram",
            JSON.stringify(myProgramsQuery.data[0])
          );
        }
      } else {
        setProgram(myProgramsQuery.data[0]);
        localStorage.setItem(
          "selectedProgram",
          JSON.stringify(myProgramsQuery.data[0])
        );
      }
    }
  }, [myProgramsQuery.data]);

  const setTermsModalData = useCallback(() => {
    setTermsModal((draft) => {
      draft.isOpen = true;
      draft.content = program?.terms?.content;
      draft.title = program?.terms?.name;
      return draft;
    });
  }, [program]);

  useEffect(() => {
    if (program && !program?.isTermAccepted && program?.terms) {
      setTermsModalData();
    }
    if (program && !program?.isActivated) {
      const studentBatchId = program?.studentBatchId;
      activateBatches.mutate(studentBatchId);
    }
  }, [program]);

  const onToggleModal = useCallback(() => {
    setTermsModal((draft) => {
      draft = initialTermsState;
      return draft;
    });
  }, [termsModal]);

  const onAcceptTerms = () => {
    const termsPayload = {
      termsId: program?.terms?.termsId,
      studentBatchId: program?.studentBatchId,
    };
    acceptTerm.mutate(termsPayload);
  };

  const onClickProgramItems = (url) => {
    if (!program?.isTermAccepted && program?.terms) {
      setTermsModalData();
    } else {
      navigate(url);
    }
  };

  return {
    myProgramsQuery,
    program,
    termsModal,
    setProgram,
    onAcceptTerms,
    onToggleModal,
    onClickProgramItems,
  };
};
