import { PageLayout } from "layouts";
import { useNotification } from "..";
import { EmptyMessage, LoadingSpinner, NotificationItem } from "shared";
import NotificationIcon from "assets/images/notification.svg";
import "./styles/notificationPill.styles.css";

export const Notifications = () => {
  const { notificationsQuery } = useNotification({
    load: true,
  });

  const { data, isLoading } = notificationsQuery;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout title="Notifications" breadcrumb={[{ label: "Notifications" }]}>
      {data?.length > 0 ? (
        <div className="mb-5">
          {data &&
            data.map((notification) => (
              <NotificationItem data={notification} key={notification?.id} />
            ))}
        </div>
      ) : (
        <EmptyMessage
          heading={"No notifications yet!"}
          subHeading={"When you get notifications,they'll show up here."}
          image={NotificationIcon}
        />
      )}
    </PageLayout>
  );
};
