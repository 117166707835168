import React from "react";
import ReactPlayer from "react-player";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "../styles/mediaPreview.styles.css";

export const MediaPreviewModal = ({
  isOpen,
  onCancel,
  title = "",
  url = "",
  mediaType = null,
}) => {
  const mediaViewer = (type) => {
    switch (type) {
      case 0:
        return <img className="media-preview-img" src={url} alt="media" />;
      case 1:
        return (
          <ReactPlayer
            className="media-preview-video"
            width="100%"
            height="auto"
            controls
            url={url}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      className="media-preview-modal"
      isOpen={isOpen}
      toggle={onCancel}
      size="lg"
      centered={true}
    >
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody className="p-0">{mediaViewer(mediaType)}</ModalBody>
    </Modal>
  );
};
