import { useCallback } from "react";
import { VideoPlayerModal, useVideoPlayer } from "shared";
import {
  PdfContent,
  ExternalLinkContent,
  VideoContent,
  VideoLinkContent,
} from "..";

export const CourseContent = (props) => {
  const { modalState, toggleModal, onSetUrl } = useVideoPlayer();

  const renderContent = useCallback(
    (content) => {
      switch (content.contentType) {
        case 0:
          var dirArray = content.fileURL.split("/");
          let dir = dirArray[dirArray.length - 2];
          let fileNames = dirArray[dirArray.length - 1];
          let filePath = `${dir}/${fileNames}`;
          return <PdfContent title={content.title} redirectTo={filePath} />;
        case 1:
          return (
            <VideoContent
              label={content.title}
              redirectTo={content.fileURL}
              onSetUrl={onSetUrl}
            />
          );
        case 2:
          return (
            <VideoLinkContent
              label={content.title}
              redirectTo={content.fileURL}
              onSetUrl={onSetUrl}
            />
          );
        case 3:
          return (
            <ExternalLinkContent
              label={content.title}
              redirectTo={content.fileURL}
            />
          );
        default:
          return null;
      }
    },
    [props.content]
  );

  return (
    <>
      {renderContent(props.content)}
      <VideoPlayerModal
        isOpen={modalState.isModalOpen}
        title={modalState.title}
        url={modalState.url}
        onCancel={() => toggleModal()}
      />
    </>
  );
};
