import { useMemo } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { trimHtmlContent } from "utils";
import "react-quill/dist/quill.snow.css";

export const IndividualNotification = ({ data, trim = false }) => {
  const { title, content, localCreatedDate, programName } = data;
  const dateFormatted = useMemo(
    () =>
      moment(localCreatedDate, "DD-MMM-YYYY HH:mm:ss A").format(
        "DD MMM, YYYY [at] hh:mm A"
      ),
    [localCreatedDate]
  );

  let parsedContent = trim ? trimHtmlContent(content, 130) : parse(content);

  return (
    <>
      <h5 className="mb-1 text-dark">{title}</h5>
      <div className="text-dark ql-editor p-0">{parsedContent}</div>
      <div className="row mt-2 gy-2">
        {programName && (
          <div className="col">
            <div className="notification-pill">{programName}</div>
          </div>
        )}
        {localCreatedDate && (
          <div
            className={`notify-time d-flex align-items-end ps-3 ${
              programName ? "col-auto" : "col justify-content-end"
            }`}
          >
            {dateFormatted}
          </div>
        )}
      </div>
    </>
  );
};
