import { useQuery } from "react-query";
import { getProfile } from "..";

const GET_USER_PROFILE = "GET_USER_PROFILE";

export const useProfile = ({ load = false }) => {
  const profilesQuery = useQuery(
    [GET_USER_PROFILE],
    async () => await getProfile(),
    {
      enabled: load,
      staleTime: Infinity,
    }
  );

  return {
    profilesQuery,
  };
};
