import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import parser from "html-react-parser";
import "../styles/dashboard.styles.css";

export const TermsModal = (props) => {
  const {
    isOpen,
    message,
    title,
    onConfirm,
    centered = false,
    size = "lg",
  } = props;
  return (
    <Modal
      className="stu-terms-modal"
      isOpen={isOpen}
      centered={centered}
      size={size}
      scrollable={true}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message && parser(message)}</ModalBody>
      <ModalFooter className="border-0">
        <Button
          color="primary"
          onClick={onConfirm}
          className="terms-modal-btn terms-accept-btn"
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
