import { Breadcrumb } from "shared";
import parse from "html-react-parser";
import { ProgressBar } from "shared";
import UserLaptopIcon from "../assets/images/user-with-laptop-blue-icon.svg";

export const DetailsPageLayout = ({
  title,
  description,
  image,
  breadcrumb,
  courseType,
  totalDuration,
  overallPercentage,
  studentDataDisplayName = false,
  onOpenModal,
  children,
}) => {
  const isOjt = courseType === 2;
  const isRegular = courseType === 0;

  return (
    <>
      <section
        className="pt-4 pb-5 single-page-header"
        style={{ backgroundColor: "#00438B" }}
      >
        <div className="container-md">
          <div className="row">
            <div className="col-12 mb-2">
              <Breadcrumb breadcrumb={breadcrumb} />
            </div>
            <div className="row d-flex justify-content-between align-items-center flex-column-reverse flex-md-row">
              <div className="col-md-8">
                {title && <h1 className="text-light">{title}</h1>}

                {description && (
                  <div className="text-light">{parse(description)}</div>
                )}
                {!isRegular && (
                  <div className="detail-page-progress-bar">
                    <ProgressBar value={overallPercentage} />
                  </div>
                )}
                {isOjt && totalDuration !== null && (
                  <div className="progress-hrs text-white mt-2">
                    {totalDuration} Min
                  </div>
                )}
                {!!studentDataDisplayName && (
                  <div>
                    <button
                      className="student-data-show-btn"
                      onClick={() =>
                        onOpenModal({ actionFrom: "student_specific_data" })
                      }
                    >
                      <img src={UserLaptopIcon} />
                      <span>{studentDataDisplayName}</span>
                    </button>
                  </div>
                )}
              </div>
              {image && (
                <div className="col-md-4 d-flex justify-content-md-end mb-3 mb-md-0">
                  <img src={image} alt="sap" style={{ height: "100px" }} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-md">
          <div className="row">
            <div className="single-content-block pt-4">{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};
