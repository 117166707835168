import React from "react";
import parse from "html-react-parser";

export const ReviewItem = ({
  question,
  index,
  markedQuestions,
  onQuestionClick,
  isTimeout,
}) => {
  const { description, questionId, sectionId, answer, choices } = question;

  const isMarked =
    markedQuestions &&
    markedQuestions?.some(
      (c) => c.questionId === questionId && c.sectionId === sectionId
    );

  const isAnswered =
    answer != null && choices?.some((c) => c.choiceId === answer.choiceId);

  const onQuestionClickHandle = () => {
    if (!isTimeout) {
      onQuestionClick(sectionId, questionId);
    }
  };

  return (
    <li className="review-item d-flex justify-content-start align-items-center">
      <div className="review-item-col review-idx-col">
        <span>{index + 1}</span>
      </div>
      <div className="review-item-col review-content-col">
        <p>{description && parse(description)}</p>
      </div>
      <div className="review-item-col review-action-col">
        <button
          disabled={isTimeout}
          className={`review-ite-nav-link ${
            isMarked && !isAnswered ? "mark-for-review" : ""
          } ${isAnswered ? "completed" : ""} ${
            !isAnswered && !isMarked ? "review-not-answered" : ""
          }`}
          onClick={onQuestionClickHandle}
        >
          {isAnswered && <span>Answered</span>}
          {isMarked && !isAnswered && <span>Marked for review</span>}
          {!isAnswered && !isMarked && <span>Not Answered</span>}
          {!isTimeout && <i className="bi bi-arrow-right"></i>}
        </button>
      </div>
    </li>
  );
};
