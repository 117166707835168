import React from "react";
import { PageLayout } from "layouts";
import { HeaderMain, Footer } from "shared";
import { useNavigate } from "react-router-dom";
import SessionExpiredImg from "assets/images/session-timeout.svg";

export const SessionExpired = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/", { replace: true });
  };
  return (
    <div id="main" className="layout-navbar">
      <HeaderMain />
      <div id="main-content-session">
        <PageLayout>
          <div className="row mb-5">
            <div className="error-page session-epired">
              <div className="error-page-img">
                <img src={SessionExpiredImg} alt="session expired" />
              </div>
              <h2>Session Expired!</h2>
              <p>
                Your account has been signed into another device or session
                expired.
              </p>
              <button onClick={goToLogin}>Go back to Login</button>
            </div>
          </div>
        </PageLayout>
      </div>
      <Footer />
    </div>
  );
};
