import { useImmer } from "use-immer";
import { createPassword } from "..";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

export const useGeneratePassword = () => {
  const navigate = useNavigate();

  const [createPasswordPayload, setCreatePasswordPayload] = useImmer({
    emailAddress: "",
    registrationId: "",
  });

  const [errorMsg, setErrorMsg] = useImmer({
    message: "",
    isError: false,
    canMakeSupportTicket: false,
  });

  const createPasswordAuth = useMutation(createPassword, {
    onSuccess: (data) => {
      navigate("/generatedResetLink", { replace: true });
    },
    onError: (data) => {
      setErrorMsg((draft) => {
        draft.message = data.response.data.message;
        draft.isError = true;
        if (data.response.data.errors && data.response.data.errors[0]) {
          draft.canMakeSupportTicket =
            data.response.data.errors[0].canMakeSupportTicket || false;
        } else {
          draft.canMakeSupportTicket = false;
        }
      });
    },
  });

  return {
    createPasswordPayload,
    setCreatePasswordPayload,
    createPasswordAuth,
    errorMsg,
  };
};
