import { Axios } from "utils";

export const getMyPrograms = async () => {
  const res = await Axios.get("/v2/portal/programs");
  return res.data;
};

export const acceptTerms = async (payload) => {
  const { termsId, studentBatchId } = payload;
  const res = await Axios.post(
    `/v2/portal/programs/terms/${termsId}/batches/${studentBatchId}`
  );
  return res.data;
};

export const activateBatch = async (studentBatchId) => {
  const res = await Axios.post(`/Program/ActivateBatch/${studentBatchId}`);
  return res.data;
};
