import { trimText } from "utils";

export const CertificateItem = ({
  certificate,
  formateDate,
  certificateAction,
  getCertificate,
  setCertificateModalState,
  downloadPdf,
}) => {
  const {
    programName = false,
    certificateName,
    image,
    localCreatedDate,
    isGenerated,
    id,
    url,
  } = certificate;

  const downloadAction = (url = "") => {
    if (url) {
      downloadPdf(url, certificateName);
    } else {
      certificateAction(id, "download", certificateName, localCreatedDate);
    }
  };

  const viewAction = (url = "") => {
    if (url) {
      setCertificateModalState((draft) => {
        draft.title = certificateName;
        draft.studentCertificateId = id;
        draft.certificateUrl = url;
        draft.isOpen = true;
        draft.certificationDate = localCreatedDate;
        return draft;
      });
    } else {
      certificateAction(id, "preview", certificateName, localCreatedDate);
    }
  };

  return (
    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 ">
      <div className="certificate-item border rounded-1 d-block">
        <div className="p-3">
          {programName && (
            <div className="mb-3">
              <div className="certificate-program-name px-3 py-1">
                {programName}
              </div>
            </div>
          )}
          <div className="certificate-thumb-holder mb-3">
            <img
              src={image}
              alt={certificateName}
              className="mb-2 certificate-item-img"
            />
          </div>
          <h3 className="text-dark mb-1 certificate-item-name">
            {trimText(certificateName)}
          </h3>
          {!isGenerated && !programName && (
            <div className="mb-0 text-black-50 certificate-item-sub-txt">
              This Certificate will be available only after the completion of
              the course.
            </div>
          )}
          {(isGenerated || !!programName) && (
            <p className="mb-0 text-black-50 certificate-item-sub-txt">
              Certified on {formateDate(localCreatedDate)}
            </p>
          )}
          {(isGenerated || !!programName) && (
            <div className="certificate-actions d-flex justify-content-between align-items-center">
              <div className="actions-left">
                <button
                  disabled={getCertificate.isLoading}
                  className="certificate-action-btn c-a-b-sm"
                  onClick={() => viewAction(url)}
                >
                  <i className="bi-eye-fill"></i>
                </button>
                <button
                  disabled={getCertificate.isLoading}
                  className="certificate-action-btn c-a-b-sm"
                  onClick={() => downloadAction(url)}
                >
                  <i className="bi-download"></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
