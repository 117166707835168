import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Input, FormFeedback, Spinner } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { WelcomeBanner, ErrorInformation, useGeneratePassword } from "..";

export const GeneratePassword = () => {
  const {
    createPasswordPayload,
    setCreatePasswordPayload,
    createPasswordAuth,
    errorMsg,
  } = useGeneratePassword();

  const { mutate, isLoading } = createPasswordAuth;
  const { emailAddress, registrationId } = createPasswordPayload;
  const [update, forceUpdate] = useState();

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const onsubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      mutate({ emailAddress, registrationId });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div>
      <div id="auth">
        <div className="row h-100">
          <WelcomeBanner />
          <div className="col-lg-6 col-12 d-flex">
            <div
              id="auth-right"
              className="auth-right-block d-flex align-items-center"
            >
              {errorMsg.isError ? (
                <ErrorInformation
                  isSupportTicketEnabled={errorMsg.canMakeSupportTicket}
                  message={errorMsg.message}
                />
              ) : (
                <div className="auth-right-form">
                  <div className="auth-back-btn mb-5">
                    <Link to="/" className="back-btn">
                      <i className="bi bi-arrow-left"></i>
                      <span>Back</span>
                    </Link>
                  </div>
                  <h4 className="auth-title text-center">Create Password</h4>
                  <p className="auth-subtitle mb-3 text-center">
                    Fill in the below items and submit to create a password for
                    your account.
                  </p>
                  <form>
                    <div className="form-group position-relative mb-4">
                      <Input
                        type="text"
                        name="registrationId"
                        className="form-control form-control-lg"
                        placeholder="Register Id"
                        value={registrationId}
                        onChange={(e) => {
                          setCreatePasswordPayload((draft) => {
                            draft.registrationId = e.target.value;
                          });
                        }}
                        invalid={validator.current.message(
                          "registrationId",
                          registrationId,
                          "required"
                        )}
                      />
                      <FormFeedback>
                        {validator.current.message(
                          "registrationId",
                          registrationId,
                          "required"
                        )}
                      </FormFeedback>
                    </div>
                    <div className="form-group position-relative mb-4">
                      <Input
                        type="email"
                        name="emailAddress"
                        className="form-control form-control-lg"
                        placeholder="Email address"
                        value={emailAddress}
                        onChange={(e) => {
                          setCreatePasswordPayload((draft) => {
                            draft.emailAddress = e.target.value;
                          });
                        }}
                        invalid={validator.current.message(
                          "emailAddress",
                          emailAddress,
                          "required|email"
                        )}
                      />
                      <FormFeedback>
                        {validator.current.message(
                          "emailAddress",
                          emailAddress,
                          "required|email"
                        )}
                      </FormFeedback>
                    </div>
                    <button
                      className="btn btn-block btn-lg shadow-lg mt-1 ni-btn-primary"
                      disabled={isLoading}
                      onClick={(e) => {
                        onsubmit(e);
                      }}
                    >
                      {isLoading ? (
                        <Spinner type="border" color="light" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              )}
              <div className="auth-right-footer">
                <p className="auth-copyright-txt">&copy; NERGY INDIA PVT LTD</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
