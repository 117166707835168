import { Routes, Route } from "react-router-dom";
import {
  Footer,
  HeaderMain,
  HeaderRightItems,
  GlobalErrorHandler,
} from "shared";
import {
  DashboardView,
  Courses,
  Certificates,
  Profile,
  Notifications,
  PDFViewer,
  Assessment,
  Help,
} from "modules";
import { QuestionnaireScope } from "../context/QuestionnaireScope";
import { PageNotFound } from "..";

export function Dashboard() {
  return (
    <QuestionnaireScope>
      <div id="app">
        <div id="main" className="layout-navbar">
          <HeaderMain>
            <HeaderRightItems />
          </HeaderMain>
          <div id="main-content">
            <GlobalErrorHandler>
              <Routes>
                <Route element={<DashboardView />} path={"/"} />
                <Route element={<Assessment />} path={"/assessment/*"} />
                <Route element={<Courses />} path={"/courses/*"} />
                <Route element={<Certificates />} path={"/certificates/*"} />
                <Route
                  element={<Certificates showAll={true} />}
                  path={"/my-certificates/*"}
                />
                <Route element={<Profile />} path={"/profile"} />
                <Route element={<Notifications />} path={"/notifications"} />
                <Route element={<PDFViewer />} path={"/content/:dir/:file"} />
                <Route element={<Help />} path={"/help"} />
                <Route element={<PageNotFound />} path={"/*"} />
                <Route element={<PageNotFound />} path={"/page-not-found"} />
              </Routes>
            </GlobalErrorHandler>
            <Footer />
          </div>
        </div>
      </div>
    </QuestionnaireScope>
  );
}
