import { Breadcrumb } from "shared";
export const PageLayout = ({ title, children, breadcrumb }) => {
  return (
    <section className="pt-2">
      <div className="container-md">
        <div className="row">
          {breadcrumb && (
            <div className="col-12 mb-2">
              <Breadcrumb breadcrumb={breadcrumb} />
            </div>
          )}
          {title && (
            <div className="col-12 mb-4">
              <h4 className="text-dark" style={{ textTransform: "capitalize" }}>
                {title}
              </h4>
            </div>
          )}
          <section className="section">
            <div className="main-content-block">{children}</div>
          </section>
        </div>
      </div>
    </section>
  );
};
