import { useQuery } from "react-query";
import { getNotifications, GET_NOTIFICATIONS } from "..";

export const useNotification = ({ load = false }) => {
  const notificationsQuery = useQuery(GET_NOTIFICATIONS, getNotifications, {
    enabled: load,
    staleTime: Infinity,
  });

  return {
    notificationsQuery,
  };
};
