import React from "react";
import { useQuery } from "react-query";
import { getAssessmentSchedule, GET_ASSESSMENT_SCHEDULE } from "..";

export const useAssessmentScheduleQuery = ({
  assessmentId = null,
  programId = null,
  scheduleMasterId = null,
  isModalOpen = false,
}) => {
  return useQuery(
    [GET_ASSESSMENT_SCHEDULE, assessmentId, programId],
    () => getAssessmentSchedule({ assessmentId, programId, scheduleMasterId }),
    {
      enabled:
        !!assessmentId && !!programId && !!scheduleMasterId && isModalOpen,
    }
  );
};
