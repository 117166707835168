import React from "react";
import { useQuery } from "react-query";
import { getAssessmentSlot, GET_ASSESSMENT_SLOT } from "..";

export const useAssessmentSlotQuery = ({
  isModalOpen = false,
  scheduleId = null,
}) => {
  return useQuery(
    [GET_ASSESSMENT_SLOT, scheduleId],
    () => getAssessmentSlot(scheduleId),
    {
      enabled: !!scheduleId && isModalOpen,
    }
  );
};
