import { GET_TASK_DETAILS_BY_ID, getTaskDetailsById } from "..";
import { useQuery } from "react-query";

export const useTaskDetailsQuery = ({ currentTask = null }) => {
  return useQuery(
    [GET_TASK_DETAILS_BY_ID, currentTask?.courseTaskId],
    () => getTaskDetailsById(currentTask?.courseTaskId),
    {
      enabled: currentTask !== null && currentTask?.courseTaskId > 0,
    }
  );
};
