import React, { useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import {
  useTaskDetailsQuery,
  useCourseQuery,
  useCourseInfoQuery,
  updateCourseTaskStatus,
  GET_COURSES_BY_ID,
  useStudentSpecificDataQuery,
} from "..";
import { useNavigate } from "react-router-dom";
import CourseIcon from "assets/images/courses_grey_icon.svg";
import PracticalsIcon from "assets/images/practicals_grey_icon.svg";
import OjtIcon from "assets/images/ojt_grey_icon.svg";
import { errorFormatter, useModal } from "shared";
import { Axios, getCookie } from "utils";
import { useMutation, useQueryClient } from "react-query";

const courseType = [
  { id: 0, type: "Regular", title: "Learning Materials", icon: CourseIcon },
  { id: 1, type: "Practicals", title: "Practicals", icon: PracticalsIcon },
  { id: 2, type: "Ojt", title: "Assignment", icon: OjtIcon },
];

export const useCourses = ({
  load = false,
  programId = "",
  type = null,
  courseId = 0,
  studentBatchId = "",
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentCourseName, setCurrentCourseName] = useImmer("");
  const [selectedCourseType, setSelectedCourseType] = useImmer(null);
  const [startSimulation, setStartSimulation] = useImmer(false);
  const [courseDetails, setCourseDetails] = useImmer(null);
  const [currentTask, setCurrentTask] = useImmer(null);
  const [taskControl, setTaskControl] = useImmer({
    currentIndex: null,
    totalTasks: null,
  });

  const localData = JSON.parse(getCookie("_stu_user_data"));
  const { refreshToken } = localData;

  const { modalState, onOpenModal, onCloseModal } = useModal();
  const { isOpen, actionFrom } = modalState;

  useEffect(() => {
    if (type != null) {
      const selected = courseType.find(
        (c) => c.type.toLowerCase() === type.toLowerCase()
      );
      if (selected) {
        setSelectedCourseType((draft) => {
          draft = selected;
          return draft;
        });
      } else {
        navigate("../../page-not-found", { replace: true });
      }
    }
  }, [type]);

  const courseQuery = useCourseQuery({
    load,
    selectedCourseType,
    studentBatchId,
  });

  const courseInfo = useCourseInfoQuery({
    courseId,
    programId,
  });

  const taskDetailsInfo = useTaskDetailsQuery({ currentTask });

  const studentSpecificDataQuery = useStudentSpecificDataQuery({
    load: isOpen && actionFrom === "student_specific_data",
    courseId,
    programId,
  });

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("currentTask");
    };
  }, []);

  useEffect(() => {
    if (courseInfo.data) {
      const localStorageTask = JSON.parse(
        sessionStorage.getItem("currentTask")
      );
      const currentTaskIndex = localStorageTask ?? 0;
      setCurrentCourseName(courseInfo.data.name);
      setCourseDetails(courseInfo.data);
      setTaskControl((draft) => {
        draft.currentIndex = currentTaskIndex;
        draft.totalTasks = courseDetails?.selectedstudentTasks?.length;
        return draft;
      });
      setCurrentTask((draft) => {
        draft =
          courseDetails?.selectedstudentTasks?.length > 0
            ? courseDetails?.selectedstudentTasks[currentTaskIndex]
            : null;
        return draft;
      });
    }
  }, [courseInfo.data, courseDetails]);

  useEffect(() => {
    if (taskControl.currentIndex !== null) {
      let isAnyTaskDeleted =
        taskControl.currentIndex === taskControl.totalTasks;
      let index = isAnyTaskDeleted
        ? taskControl.currentIndex - 1
        : taskControl.currentIndex;
      const currentTask =
        courseDetails?.selectedstudentTasks?.length > 0 &&
        courseDetails?.selectedstudentTasks[index];

      setCurrentTask((draft) => {
        draft = currentTask;
        return draft;
      });
      if (isAnyTaskDeleted) {
        setTaskControl((draft) => {
          draft.currentIndex = taskControl.currentIndex - 1;
          return draft;
        });
      }
    }
  }, [taskControl, setTaskControl]);

  const onSetSelectedTask = useCallback(
    (index) => {
      sessionStorage.setItem("currentTask", JSON.stringify(index));
      setTaskControl((draft) => {
        draft.currentIndex = index;
        return draft;
      });
    },
    [taskControl]
  );

  const onNextTask = useCallback(() => {
    const nextTaskIndex = taskControl.currentIndex + 1;
    if (nextTaskIndex < taskControl.totalTasks) {
      onSetSelectedTask(nextTaskIndex);
    }
  }, [taskControl]);

  const onPrevTask = useCallback(() => {
    const prevTaskIndex = taskControl.currentIndex - 1;
    if (prevTaskIndex >= 0) {
      onSetSelectedTask(prevTaskIndex);
    }
  }, [taskControl]);

  const updateCourseTasksStatus = useMutation(updateCourseTaskStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_COURSES_BY_ID, programId, courseId]);
    },
    onError: (e) => {
      errorFormatter(e);
    },
  });

  const onStartSimulation = async () => {
    const newWindow = window.open("", "_blank");
    if (!newWindow) {
      alert("Pop-up blocked. Please allow pop-ups for this site.");
      return;
    }

    try {
      const res = await Axios.get(
        `/CourseTasks/GenerateSimulationToken/${currentTask?.id}?refreshToken=${refreshToken}`
      );
      if (res.status === 200) {
        const { id } = currentTask;
        newWindow.location.href = `${taskDetailsInfo?.data?.endPoint}?taskId=${id}&token=${res.data}&callback=${process.env.REACT_APP_BASE_URL}`;
      } else {
        throw Error;
      }
    } catch (err) {
      newWindow.close();
    }
  };

  return {
    courseQuery,
    courseInfo,
    courseDetails,
    currentCourseName,
    taskControl,
    onSetSelectedTask,
    currentTask,
    onNextTask,
    onPrevTask,
    selectedCourseType,
    onStartSimulation,
    taskDetailsInfo,
    updateCourseTasksStatus,
    modalState,
    onOpenModal,
    onCloseModal,
    studentSpecificDataQuery,
  };
};
