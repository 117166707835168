import React from "react";
import { Progress } from "reactstrap";

export const ProgressBar = (props) => {
  const { color = "warning", value, ...rest } = props;

  return (
    <div className="progress-bar-block">
      <div className="progress-item">
        <Progress
          color={color}
          value={value}
          style={{
            height: "10px",
          }}
          {...rest}
        />
      </div>
      <div className="progress-count">{`${value}%`}</div>
    </div>
  );
};
