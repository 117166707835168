import React from "react";
import { useQuery } from "react-query";
import { getAssessmentList, GET_ASSESSMENT_LIST } from "..";

export const useAssessmentQuery = ({ load = false, programId = null }) => {
  return useQuery(
    [GET_ASSESSMENT_LIST, programId],
    () => getAssessmentList(programId),
    {
      enabled: load && !!programId,
      refetchOnWindowFocus: true,
    }
  );
};
