import React from "react";
import { useQuery } from "react-query";
import { getQuestionnaire, START_ASSESSMENT } from "..";

export const useStartAssessmentQuery = ({
  enabled,
  assessmentId,
  slotId,
  attemptId,
}) => {
  return useQuery(
    [START_ASSESSMENT, assessmentId],
    () =>
      getQuestionnaire({
        assessmentId,
        slotId,
        attemptId,
      }),
    {
      enabled,
    }
  );
};
