import { PageLayout } from "layouts";
import NotFound from "assets/images/page-not-found.svg";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/", { replace: true });
  };
  return (
    <PageLayout>
      <div className="row mb-5">
        <div className="error-page page-not-found">
          <div className="error-page-img">
            <img src={NotFound} alt="not found" />
          </div>
          <h2>Page Not Found!</h2>
          <p>The requested URL was not found on this server.</p>
          <button onClick={goToHome}>Go back to Home</button>
        </div>
      </div>
    </PageLayout>
  );
};
