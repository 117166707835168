import { PageLayout } from "layouts";
import { useProfile, useChangePassword, Avatar, ChangePasswordModal } from "..";
import { LoadingSpinner } from "shared";
import { useAuthenticate } from "master";
import "./styles/profile.style.css";

export const Profile = () => {
  const { onUserSignOut, signoutAccount } = useAuthenticate();
  const { profilesQuery } = useProfile({ load: true });
  const { isModalOpen, setIsModalOpen } = useChangePassword({ load: true });

  if (profilesQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout title="My Profile" breadcrumb={[{ label: "My Profile" }]}>
      <div className="row mb-5">
        <div className="ms-auto me-auto pt-2 pb-2 profile-inner-block">
          <Avatar
            userName={[
              profilesQuery?.data?.firstName,
              profilesQuery?.data?.lastName,
            ]}
          />
          <div className="user-content-block">
            <div className="d-flex justify-content-start align-items-center pt-4 pb-3 ps-2 pe-2 mb-3 border-bottom">
              <label htmlFor="userFullname" className="col-4 col-form-label">
                Full Name :
              </label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id="userFullname"
                  value={`${profilesQuery?.data?.firstName} ${
                    profilesQuery?.data?.lastName ?? ""
                  }`}
                  readOnly
                />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center pt-4 pb-3 ps-2 pe-2 mb-3 border-bottom">
              <label htmlFor="userEmail" className="col-4 col-form-label">
                Email Address :
              </label>
              <div className="col-8">
                <input
                  type="email"
                  className="form-control"
                  id="userEmail"
                  value={profilesQuery.data.emailAddress}
                  readOnly
                />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center pt-5 pb-3 ps-2 pe-2 mt-3 mb-4 border-bottom">
              <span className="col-4">
                <strong>Change Your Password</strong>
              </span>
              <div className="col-8 text-end">
                <button
                  type="button"
                  className="btn btn-md px-5 section-btn"
                  onClick={() => setIsModalOpen(true)}
                >
                  Change
                </button>
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn ni-btn-primary py-2 px-5"
                onClick={onUserSignOut}
                disabled={signoutAccount.isLoading}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        size="md"
        isOpen={isModalOpen}
        title="Change Your Password?"
        onCancel={() => setIsModalOpen(false)}
      />
    </PageLayout>
  );
};
