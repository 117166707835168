import React from "react";
import { TasksSidebar, TasksContent, TaskFooterNavigation } from "..";
import { EmptyMessage } from "shared";
import { useNavigate } from "react-router-dom";
import emptyIcon from "assets/images/ojt_grey_icon.svg";

export const CourseTasks = (props) => {
  const history = useNavigate();
  const {
    tasks,
    currentTask,
    onSetSelectedTask,
    onNextTask,
    onPrevTask,
    courseType,
    taskControl,
    onStartSimulation,
    taskDetailsInfo,
    updateCourseTasksStatus,
  } = props;

  return (
    <>
      {currentTask ? (
        <>
          <div className="tasks-wrapper d-flex justify-content-start flex-sm-column flex-md-row align-items-start">
            <TasksSidebar
              tasks={tasks}
              onSetSelectedTask={onSetSelectedTask}
              courseType={courseType}
              taskControl={taskControl}
            />
            <div className="col-md-9 tasks-content-wrapper">
              <TasksContent taskDetailsInfo={taskDetailsInfo} />
            </div>
          </div>
          <div className="col-md-9 tasks-content-wrapper float-end">
            <TaskFooterNavigation
              currentTask={currentTask}
              courseType={courseType}
              onNextTask={onNextTask}
              onPrevTask={onPrevTask}
              onStartSimulation={onStartSimulation}
              taskControl={taskControl}
              taskDetailsInfo={taskDetailsInfo}
              updateCourseTasksStatus={updateCourseTasksStatus}
              isTaskDetailsLoading={taskDetailsInfo.isLoading}
            />
          </div>
        </>
      ) : (
        <div className="text-center mt-4">
          <EmptyMessage
            heading={"No task found!"}
            image={emptyIcon}
            subHeading={`There is no task assigned for you at the moment. Please check back later.`}
            onCancel={() => history(-1)}
          />
        </div>
      )}
    </>
  );
};
