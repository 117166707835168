import { GET_COURSES_BY_PROGRAM_ID, getCourses } from "..";
import { useQuery } from "react-query";

export const useCourseQuery = ({
  load = false,
  selectedCourseType = null,
  studentBatchId = "",
}) => {
  return useQuery(
    `${GET_COURSES_BY_PROGRAM_ID}_${selectedCourseType?.id}_${studentBatchId}`,
    () => getCourses(selectedCourseType?.id, studentBatchId),
    {
      enabled: load && studentBatchId !== "" && selectedCourseType !== null,
    }
  );
};
