import React, { useCallback } from "react";
import { useImmer } from "use-immer";
import { supportTicket } from "..";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { successMessage } from "utils";
import { errorFormatter } from "shared";

const initialState = {
  name: "",
  registrationId: "",
  email: "",
  phonenumber: "",
  subject: "",
  message: "",
};

export const useSupportTicket = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useImmer(false);
  const [ticketValues, setTicketValues] = useImmer(initialState);

  const resetSupportTicketForm = useCallback(() => {
    setTicketValues((draft) => {
      draft = initialState;
      return draft;
    });
  }, [ticketValues]);

  const supportTickets = useMutation(supportTicket, {
    onSuccess: (data) => {
      successMessage("Support ticket has been submitted!");
      resetSupportTicketForm();
      navigate("/", { replace: true });
    },
    onError: (data) => {
      errorFormatter(data);
    },
  });

  return {
    ticketValues,
    setTicketValues,
    supportTickets,
    isModalOpen,
    setIsModalOpen,
    resetSupportTicketForm,
  };
};
