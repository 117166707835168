import React, { useCallback } from "react";
import { PageLayout } from "layouts";
import { LoadingSpinner, EmptyMessage } from "shared";
import { useQuestionareContext } from "master";
import { useNavigate } from "react-router-dom";
import AssessmentIcon from "assets/images/assessment-icon.svg";
import {
  AssessmentScheduleModal,
  AssessmentItem,
  useAssessment,
  SlotConfirmModal,
  SlotSuccessfullModal,
  SlotUnsuccessfullModal,
} from "..";
import moment from "moment";
import { clearLocalKeysWithPrefix } from "utils";

export const AssessmentList = () => {
  const navigate = useNavigate();
  const questionnaireContext = useQuestionareContext();
  const { onToggleInstruction, setMarkedQuestions } = questionnaireContext;
  const {
    assessmentsQuery,
    assessmentScheduleQuery,
    assessmentSlotQuery,
    setScheduleId,
    scheduleId,
    selectedSlotId,
    setSelectedSlotId,
    createSchedule,
    isSlotInfoOpen,
    setIsSlotInfoOpen,
    onAssessmentScheduleModal,
  } = useAssessment({
    load: true,
  });

  const {
    isLoading,
    data: assessmentListData,
    isRefetching,
    isFetching,
  } = assessmentsQuery;
  const { data: schedules } = assessmentScheduleQuery;
  const scheduleDateList = schedules?.map((s) => {
    const isPastDate = moment(s.scheduledDate, "DD-MMM-YYYY").isBefore(
      moment(),
      "day"
    );
    return {
      label: s.scheduledDate,
      value: s.scheduleId,
      isDisabled: isPastDate,
    };
  });

  const selectedProgram = JSON.parse(localStorage.getItem("selectedProgram"));
  const { data: slots } = assessmentSlotQuery;

  const onCreateAssessmentSchedule = useCallback(() => {
    setIsSlotInfoOpen((draft) => {
      draft.type = "confirm";
      draft.isOpen = true;
      return draft;
    });
  }, [isSlotInfoOpen]);

  const onStartAssessment = ({
    assessmentId,
    slotId,
    attemptId,
    noOfQuestions,
    maxTime,
    totalMark,
    maxAttempt,
    instructions,
    name,
    attemptStatus,
    isStartAssessment = false,
  }) => {
    const isStart = attemptStatus === 2;
    const isScheduled = attemptStatus === 1;

    if (isStart || isScheduled) {
      setMarkedQuestions((draft) => {
        draft = [];
        return draft;
      });
    }
    if (isStartAssessment) {
      clearLocalKeysWithPrefix("assessmentData_", "sessionStorage");
      clearLocalKeysWithPrefix("currentQuestionIndex_", "sessionStorage");
    }
    clearLocalKeysWithPrefix("assessmentMasterId_", "sessionStorage");
    sessionStorage.setItem("assessmentId", assessmentId);
    sessionStorage.setItem("slotId", slotId);
    sessionStorage.setItem("attemptId", attemptId);

    const instructionsPayload = {
      noOfQuestions,
      maxTime,
      totalMark,
      maxAttempt,
      instructions,
      name,
    };
    onToggleInstruction(instructionsPayload, "assessmentList");
  };

  const slotInfoModalClose = useCallback(() => {
    setIsSlotInfoOpen((draft) => {
      draft.isOpen = false;
      draft.type = "";
      return draft;
    });
  }, [isSlotInfoOpen]);

  const onConfirm = () => {
    createSchedule.mutate({ slotId: selectedSlotId });
    slotInfoModalClose();
  };

  const breadcrumb = [
    { label: selectedProgram.name, location: "/home" },
    { label: "Assessment" },
  ];

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      {assessmentListData && (
        <PageLayout title={"Assessment"} breadcrumb={breadcrumb}>
          {assessmentListData?.length > 0 && (
            <div className="row mb-5">
              <div className="col-12">
                {assessmentListData?.map((item, index) => {
                  return (
                    <AssessmentItem
                      key={`assessment-item-${item.assessmentId}-${index}`}
                      assessmentData={item}
                      onScheduleAssessment={onAssessmentScheduleModal}
                      onStartAssessment={onStartAssessment}
                      isRefetching={isRefetching}
                      isFetching={isFetching || createSchedule.isLoading}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {assessmentListData?.length === 0 && (
            <EmptyMessage
              heading={"No Assessments found"}
              subHeading={
                <span>
                  There is no Assessments available for you at the moment.
                  <br />
                  Please check back later.
                </span>
              }
              image={AssessmentIcon}
              onCancel={() => navigate(`/home`)}
            />
          )}
          {/* SCHEDULE MODAL */}
          {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "scheduleModal" && (
            <AssessmentScheduleModal
              isOpen={
                isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "scheduleModal"
              }
              setScheduleId={setScheduleId}
              scheduleId={scheduleId}
              selectedSlotId={selectedSlotId}
              setSelectedSlotId={setSelectedSlotId}
              scheduleDateList={scheduleDateList}
              slots={slots}
              onCreateAssessmentSchedule={onCreateAssessmentSchedule}
              onCancel={() => {
                setIsSlotInfoOpen((draft) => {
                  draft.isOpen = false;
                  draft.type = "";
                  return draft;
                });
              }}
              setIsSlotInfoOpen={setIsSlotInfoOpen}
              isSlotInfoOpen={isSlotInfoOpen}
            />
          )}
          {/* ASSESSMENT SLOT MODAL */}
          {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "confirm" && (
            <SlotConfirmModal
              isOpen={
                isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "confirm"
              }
              onCancel={slotInfoModalClose}
              data={isSlotInfoOpen?.data}
              onConfirm={onConfirm}
            />
          )}
          {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "success" && (
            <SlotSuccessfullModal
              className="assessment-modal"
              isOpen={
                isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "success"
              }
              onCancel={slotInfoModalClose}
              data={isSlotInfoOpen?.data}
              onConfirm={slotInfoModalClose}
            />
          )}
          {isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "unsuccess" && (
            <SlotUnsuccessfullModal
              className="assessment-modal"
              isOpen={
                isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "unsuccess"
              }
              onCancel={slotInfoModalClose}
              data={isSlotInfoOpen?.data}
              onConfirm={slotInfoModalClose}
            />
          )}
        </PageLayout>
      )}
    </>
  );
};
