import React, { useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { FormFeedback, Spinner } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { WelcomeBanner, useResetPassword } from "..";
import { PasswordInput } from "shared";
import { strongPassword } from "utils";

export function ResetPassword() {
  const [searchParams] = useSearchParams();

  const emailAddress = searchParams.get("email");
  const passwordResetToken = searchParams.get("token");

  const { resetPassPayload, setResetPassPayload, resetPasswordAuth } =
    useResetPassword();
  const { mutate, isLoading } = resetPasswordAuth;
  const [update, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: strongPassword,
    })
  );

  const onsubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      mutate({
        ...resetPassPayload,
        emailAddress,
        passwordResetToken,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div>
      <div id="auth">
        <div className="row h-100">
          <WelcomeBanner />
          <div className="col-lg-6 col-12 d-flex">
            <div
              id="auth-right"
              className="auth-right-block d-flex align-items-center"
            >
              <div className="auth-right-form">
                <div className="auth-back-btn mb-5">
                  <Link to="/" className="back-btn">
                    <i className="bi bi-arrow-left"></i>
                    <span>Back</span>
                  </Link>
                </div>
                <h4 className="auth-title text-center">Reset Your Password</h4>
                <p className="auth-subtitle mb-3 text-center">
                  Create New Password for Your Account
                </p>
                <form>
                  <div className="form-group position-relative mb-4 has-icon-right">
                    <label className="mb-1">Password</label>
                    <PasswordInput
                      name="password"
                      className="form-control form-control-lg"
                      value={resetPassPayload.newPassword}
                      onChange={(e) => {
                        setResetPassPayload((draft) => {
                          draft.newPassword = e.target.value;
                        });
                      }}
                      invalid={validator.current.message(
                        "password",
                        resetPassPayload.newPassword,
                        "required|min:8|upper|lower|digit|special"
                      )}
                    />
                    <FormFeedback>
                      {validator.current.message(
                        "password",
                        resetPassPayload.newPassword,
                        "required|min:8|upper|lower|digit|special"
                      )}
                    </FormFeedback>
                  </div>
                  <div className="form-group position-relative mb-4 has-icon-right">
                    <label className="mb-1">Confirm Password</label>
                    <PasswordInput
                      name="confirmPassword"
                      className="form-control form-control-lg"
                      value={resetPassPayload.confirmPassword}
                      onChange={(e) => {
                        setResetPassPayload((draft) => {
                          draft.confirmPassword = e.target.value;
                        });
                      }}
                      invalid={validator.current.message(
                        "confirmPassword",
                        resetPassPayload.confirmPassword,
                        `required|in:${resetPassPayload.newPassword}`
                      )}
                    />
                    <FormFeedback>
                      {validator.current.message(
                        "confirmPassword",
                        resetPassPayload.confirmPassword,
                        `required|in:${resetPassPayload.newPassword}`,
                        {
                          messages: {
                            in: "Password and confirm password must be same!",
                          },
                        }
                      )}
                    </FormFeedback>
                  </div>
                  <button
                    className="btn btn-block btn-lg shadow-lg mt-1 ni-btn-primary"
                    disabled={isLoading}
                    onClick={(e) => {
                      onsubmit(e);
                    }}
                  >
                    {isLoading ? (
                      <Spinner type="border" color="light" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
              <div className="auth-right-footer">
                <p className="auth-copyright-txt">&copy; NERGY INDIA PVT LTD</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
