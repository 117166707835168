import React, { useEffect } from "react";
import { PageLayout } from "layouts";
import { ReviewItem, AnswerSubmitConfirmModal } from "..";
import { useNavigate } from "react-router-dom";
import { useQuestionareContext } from "master";

export const Review = () => {
  const navigate = useNavigate();
  const questionnaireContext = useQuestionareContext();
  const {
    questionnaire,
    markedQuestions,
    onFindQuestion,
    isModalOpen,
    setIsModalOpen,
    isTimeout,
    disableBackButton,
    onSubmitAnswer,
    submitAllAnswers,
  } = questionnaireContext;

  const isSubmittingAnswers = submitAllAnswers?.isLoading;

  const onQuestionClick = (sectionId, questionId) => {
    onFindQuestion(sectionId, questionId);
    navigate("/home/assessment/start");
  };

  useEffect(() => {
    if (isTimeout) {
      disableBackButton();
    }
  }, [isTimeout]);

  const goBackToQuestions = () => {
    if (!isTimeout) {
      navigate("/home/assessment/start", { replace: true });
    }
  };

  return (
    <PageLayout>
      <div className="row">
        <div className="col-12">
          <div className="review-page-header d-flex justify-content-between align-iems-center">
            <h3 className="mb-0">Review Your Answer</h3>
            <div className="review-header-sub-row d-flex justify-content-between align-iems-center">
              <div className="review-header-actions">
                <button
                  onClick={goBackToQuestions}
                  className="assessment-md-btn me-2 tile-btn-white"
                  disabled={isTimeout}
                >
                  Back
                </button>
                <button
                  onClick={() =>
                    setIsModalOpen((draft) => {
                      draft.type = "submitConfirm";
                      draft.isOpen = true;
                      return draft;
                    })
                  }
                  className="assessment-md-btn ms-2 tile-btn-blue"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="review-items-list">
            <ul className="review-item-ul">
              {questionnaire?.questions.map((question, index) => {
                return (
                  <ReviewItem
                    key={question.questionId}
                    question={question}
                    index={index}
                    markedQuestions={markedQuestions}
                    onQuestionClick={onQuestionClick}
                    isTimeout={isTimeout}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {isModalOpen.isOpen && isModalOpen.type === "submitConfirm" && (
        <AnswerSubmitConfirmModal
          isOpen={isModalOpen.isOpen && isModalOpen.type === "submitConfirm"}
          onCancel={() => {
            if (!isSubmittingAnswers) {
              setIsModalOpen((draft) => {
                draft.type = "";
                draft.isOpen = false;
                return draft;
              });
            }
          }}
          onSubmit={onSubmitAnswer}
          size={"md"}
          title={"Assessment Submit"}
          isSubmittingAnswers={isSubmittingAnswers}
        />
      )}
    </PageLayout>
  );
};
