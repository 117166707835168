import { useImmer } from "use-immer";
import { changePassword } from "..";
import { useMutation } from "react-query";
import { errorMessage, successMessage, removeCookie } from "utils";

export const useChangePassword = () => {
  const [isModalOpen, setIsModalOpen] = useImmer(false);
  const [changePasswordPayload, setChangePasswordPayload] = useImmer({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const changePasswordAuth = useMutation(changePassword, {
    onSuccess: (data) => {
      successMessage("Your password has been changed. Please login again!");
      removeCookie("_stu_user_data");
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    },
    onError: (data) => {
      if ("ConfirmPassword" in data.response.data.errors) {
        errorMessage(data.response.data.errors.ConfirmPassword);
      } else {
        errorMessage(data.response.data.errors[0]);
      }
    },
  });

  return {
    changePasswordPayload,
    setChangePasswordPayload,
    changePasswordAuth,
    isModalOpen,
    setIsModalOpen,
  };
};
