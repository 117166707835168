import React from "react";
import { PageLayout } from "layouts";
import { EmptyMessage, HeaderMain, Footer, LoadingSpinner } from "shared";
import parse from "html-react-parser";
import { useSettings } from "..";

export const Faq = () => {
  const { settingsInfo } = useSettings({ load: true, key: "page_FAQ" });
  const { data, isLoading } = settingsInfo;

  if (isLoading) return <LoadingSpinner />;

  return (
    <div id="main" className="layout-navbar">
      <HeaderMain />
      <div id="main-content">
        <PageLayout title={"FAQ"}>
          {data && (data !== "" || data !== null) ? (
            <div className="mb-5 text-dark">{parse(data.content)}</div>
          ) : (
            <div>
              <EmptyMessage
                heading={"No FAQ found"}
                subHeading={"There is no FAQ."}
              />
            </div>
          )}
        </PageLayout>
      </div>
      <Footer />
    </div>
  );
};
