import React, { useEffect } from "react";
import { PageLayout } from "layouts";
import { LoadingSpinner } from "shared";
import { useAssessment, ResultCard, PublishSoonCard } from "..";
import { useQuestionareContext } from "master";

export const AssessmentResult = () => {
  const questionnaireContext = useQuestionareContext();
  const { disableBackButton } = questionnaireContext;

  useEffect(() => {
    disableBackButton();
  }, []);

  const assessmentId = JSON.parse(sessionStorage.getItem("assessmentId"));
  const assessmentMasterId = sessionStorage.getItem(
    `assessmentMasterId_${assessmentId}`
  );
  const { assessmentResultQuery } = useAssessment({
    load: false,
    assessmentMasterId,
  });

  const { data: assessmentResult, isLoading } = assessmentResultQuery;

  const isPassedAssessment =
    assessmentResult && assessmentResult?.resultStatus === 4;
  const isFailedAssessment =
    assessmentResult && assessmentResult?.resultStatus === 5;
  const isAwaitingResult =
    assessmentResult && assessmentResult?.resultStatus === 6;

  const goToDashboard = () => {
    sessionStorage.removeItem(`assessmentData_${assessmentId}`);
    sessionStorage.removeItem(`currentQuestionIndex_${assessmentId}`);
    sessionStorage.removeItem(`assessmentMasterId_${assessmentId}`);
    sessionStorage.removeItem("markedQuestions");
    sessionStorage.removeItem("slotId");
    sessionStorage.removeItem("attemptId");
    sessionStorage.removeItem("assessmentId");
    window.close();
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <PageLayout>
      <div className="row">
        <div className="col-12">
          <div className="assessment-result-wrap">
            {(isPassedAssessment || isFailedAssessment) && (
              <ResultCard
                assessmentResult={assessmentResult}
                goToDashboard={goToDashboard}
              />
            )}
            {isAwaitingResult && (
              <PublishSoonCard
                message={assessmentResult?.resultStatusStr}
                goToDashboard={goToDashboard}
              />
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
