import { useImmer } from "use-immer";
import { resetPassword } from "..";
import { useMutation } from "react-query";
import { successMessage } from "utils";
import { errorFormatter } from "shared";

export const useResetPassword = () => {
  const [resetPassPayload, setResetPassPayload] = useImmer({
    newPassword: "",
    confirmPassword: "",
  });

  const resetPasswordAuth = useMutation(resetPassword, {
    onSuccess: (data) => {
      successMessage("Your password has been changed. Please login again!");
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    },
    onError: (data) => {
      errorFormatter(data);
    },
    onSettled: () => {},
  });

  return {
    resetPassPayload,
    setResetPassPayload,
    resetPasswordAuth,
  };
};
