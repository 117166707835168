import { Axios } from "utils";

export const getProfile = async () => {
  const res = await Axios.get("/Users/profile");
  return res.data;
};

export const changePassword = async (auth) => {
  const res = await Axios.post("/Users/ChangePassword", auth);
  return res.data;
};
