import React from "react";

export const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#00438B" }}>
      <div className="container-md">
        <div className="row">
          <div className="d-flex justify-content-between align-items-center pt-3 pb-3 flex-column flex-md-row">
            <ul className="ni-footer-nav d-flex justify-content-center align-items-center mb-3 ps-0 mb-md-0">
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="/faq">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a href="/privacy" className="nav-link" target="_blank">
                  Privacy policy
                </a>
              </li>
              <li className="nav-item">
                <a href="/terms" className="nav-link" target="_blank">
                  Terms and Conditions
                </a>
              </li>
            </ul>
            <span className="footer-copytext">&copy; NERGY INDIA PVT LTD</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
