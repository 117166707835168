import { Link } from "react-router-dom";

export const Breadcrumb = ({ breadcrumb }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb" style={{ textTransform: "capitalize" }}>
        {breadcrumb.length <= 1 && (
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/home">Home</Link>
          </li>
        )}
        {breadcrumb.map((item, idx) => {
          if (breadcrumb.length <= 1) {
            return (
              <li
                key={idx}
                className="breadcrumb-item active"
                aria-current="page"
              >
                {item.label}
              </li>
            );
          }
          return (
            <li
              key={idx}
              className={`${item.location ? "" : "active"} breadcrumb-item`}
            >
              {item.location ? (
                <Link to={item.location}>{item.label}</Link>
              ) : (
                item.label
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
