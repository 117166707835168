export const ProgramTile = ({ title, icon, onClick }) => {
  return (
    <div className="col-6 col-md-4 mb-4">
      <button
        onClick={onClick}
        className="course-sm-items text-center rounded-1"
      >
        <div className="p-4">
          <img src={icon} alt={title} />
          <p className="mb-0">{title}</p>
        </div>
      </button>
    </div>
  );
};
