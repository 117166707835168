import { useImmer } from "use-immer";

const initialModalState = {
  isOpen: false,
  actionFrom: "",
  info: null,
};

export const useModal = () => {
  const [modalState, setModalState] = useImmer(initialModalState);

  const onCloseModal = () => {
    setModalState((draft) => {
      draft = initialModalState;
      return draft;
    });
  };

  const onOpenModal = ({ actionFrom, info }) => {
    onCloseModal();
    setModalState((draft) => {
      draft.isOpen = true;
      draft.actionFrom = actionFrom;
      draft.info = info;
      return draft;
    });
  };

  return { modalState, onOpenModal, onCloseModal };
};
