import { Axios } from "utils";

export const getAssessmentList = async (programId) => {
  const res = await Axios.get(`/v2/portal/assessments/programs/${programId}`);
  return res.data;
};

export const getAssessmentSchedule = async ({
  assessmentId,
  programId,
  scheduleMasterId,
}) => {
  const res = await Axios.get(
    `/v2/portal/Assessments/${assessmentId}/programs/${programId}/schedules/${scheduleMasterId}`
  );
  return res.data;
};

export const getAssessmentSlot = async (scheduleId) => {
  const res = await Axios.get(
    `/v2/portal/assessments/schedules/${scheduleId}/slots`
  );
  return res.data;
};

export const createAssessmentSchedule = async (payload) => {
  const res = await Axios.post(
    `/v2/portal/assessments/${payload.slotId}/create-schedule`,
    payload
  );
  return res.data;
};

export const assessmentResult = async (assessmentMasterId) => {
  const res = await Axios.get(
    `/Assessments/student/result/${assessmentMasterId}`
  );
  return res.data;
};
