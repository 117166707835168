import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router";
import "../styles/globalErrorHandler.style.css";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const isProduction = process.env?.REACT_APP_ENVIORNMENT_KEY === "production";
  return (
    <div role="alert" className="global-error-wrap">
      <div>
        <code>{isProduction ? "Something went wrong!" : error.message}</code>
      </div>
      <a onClick={resetErrorBoundary}>Go to Home page</a>
    </div>
  );
};

export const GlobalErrorHandler = ({ children }) => {
  const navigate = useNavigate();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        navigate("/home", { replace: true });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
