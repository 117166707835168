import WarningIcon from "assets/images/warning-yellow-sign.svg";
import { SupportTicketModal } from ".";
import { useSupportTicket } from "..";
import { Link } from "react-router-dom";

export function ErrorInformation({ message, isSupportTicketEnabled }) {
  const { isModalOpen, setIsModalOpen } = useSupportTicket();

  return (
    <>
      <div className="auth-right-form">
        <h4 className="auth-title text-center">Create Password</h4>
        <img className="auth-img mx-auto d-block mb-3 mt-3" src={WarningIcon} />
        <h4 className="auth-title text-center">Oh no!</h4>
        <p className="auth-subtitle mb-3 text-center">{message}</p>
        <Link to="/" className="btn btn-block btn-lg shadow-lg ni-btn-primary">
          Go to login
        </Link>
        {isSupportTicketEnabled && (
          <div className="col-12 text-center mt-4">
            <p style={{ fontSize: "16px" }}>
              Need help?{" "}
              <strong
                style={{ color: "#00438b", cursor: "pointer" }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Raise a Support Ticket
              </strong>
            </p>
          </div>
        )}
      </div>
      <SupportTicketModal
        size="md"
        isOpen={isModalOpen}
        title="Support Ticket"
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
}
