export const WelcomeBanner = () => {
  const Nergy360Logo = process.env.REACT_APP_MAIN_LOGO_WHITE;

  return (
    <div className="col-lg-6 d-none d-lg-block position-relative">
      <div
        id="auth-left"
        className="d-flex align-items-center justify-content-center auth-left-content-box"
        style={{ height: "100vh" }}
      >
        <div className="auth-logo">
          <img
            style={{ height: "100px" }}
            src={Nergy360Logo}
            alt="Logo"
            className="mb-2"
          />
        </div>
      </div>
      <div className="auth-left-footer">
        <ul className="ps-0">
          <li>
            <a href="/faq" className="nav-link" target="_blank">
              FAQ
            </a>
          </li>
          <li>
            <a href="/privacy" className="nav-link" target="_blank">
              Privacy policy
            </a>
          </li>
          <li>
            <a href="/terms" className="nav-link" target="_blank">
              Terms and Conditions
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
