import React, { useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputControl from "shared/components/InputControl";
import { TimeSelector } from "..";

export const AssessmentScheduleModal = ({
  isOpen,
  onCancel,
  setScheduleId,
  scheduleId,
  scheduleDateList,
  slots,
  setSelectedSlotId,
  selectedSlotId,
  onCreateAssessmentSchedule,
  setIsSlotInfoOpen,
  isSlotInfoOpen,
  ...rest
}) => {
  const onSelectAssessmentDate = useCallback(
    (e) => {
      const { value } = e;
      setScheduleId((draft) => {
        draft = value;
        return draft;
      });
    },
    [scheduleId]
  );

  const onSelectSlot = useCallback(
    ({ slotId, scheduledDate, startAtStr, endAtStr }) => {
      setSelectedSlotId((draft) => {
        draft = slotId;
        return draft;
      });
      setIsSlotInfoOpen((draft) => {
        draft.data = {
          scheduledDate,
          startAtStr,
          endAtStr,
        };
      });
    },
    [selectedSlotId, isSlotInfoOpen]
  );

  return (
    <Modal
      isOpen={isOpen}
      size={"md"}
      {...rest}
      className="assessment-modal"
      fullscreen={"sm"}
      scrollable={true}
    >
      <ModalHeader toggle={() => onCancel(null)}>
        Schedule Assessment
      </ModalHeader>
      <ModalBody style={{ minHeight: "420px" }}>
        <div className="row">
          <div className="col-12">
            <div className="form-group assessment-date-picker">
              <label className="mb-2" htmlFor="contact-date-vertical">
                Select a date for assessment
              </label>
              <InputControl
                closeMenuOnSelect
                type="react-select"
                options={scheduleDateList}
                name="assessmentDate"
                value={
                  scheduleDateList &&
                  scheduleDateList.find((s) => s.value === scheduleId)
                }
                isValid={true}
                onChange={onSelectAssessmentDate}
              />
            </div>
          </div>
          {slots && slots.slots?.length > 0 ? (
            <div className="col-12 mt-3">
              <p>Select your suitable assessment time</p>
              <div className="d-flex flex-wrap time-selector-group">
                {slots.slots?.map((slot) => {
                  return (
                    <TimeSelector
                      key={slot.slotId}
                      onSelectTime={() =>
                        onSelectSlot({
                          slotId: slot.slotId,
                          scheduledDate: slots.scheduledDate,
                          startAtStr: slot.startAtStr,
                          endAtStr: slot.endAtStr,
                        })
                      }
                      isEnabled={slot.isEnabled}
                      isSelected={selectedSlotId === slot.slotId}
                      startAtStr={slot.startAtStr}
                      endAtStr={slot.endAtStr}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="col-12 mt-3">
              <i>Please select a date to show available slots.</i>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="col-12 text-end mt-3 pb-2">
          <button
            onClick={onCreateAssessmentSchedule}
            className="assessment-md-btn tile-btn-blue"
            disabled={!selectedSlotId}
          >
            Schedule Assessment
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
