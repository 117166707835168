import React from "react";

export const TimeSelector = ({
  isEnabled,
  isSelected,
  startAtStr,
  endAtStr,
  onSelectTime,
}) => {
  return (
    <div className="time-selector-div">
      <button
        className={`time-selector-button ${isSelected ? "active" : ""}`}
        disabled={!isEnabled}
        onClick={onSelectTime}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="timer-icon"
        >
          <path
            d="M22.5002 7.33398C13.3102 7.33398 5.8335 14.8107 5.8335 24.0007C5.8335 33.1906 13.3102 40.6673 22.5002 40.6673C31.6901 40.6673 39.1668 33.1906 39.1668 24.0007C39.1668 14.8107 31.6901 7.33398 22.5002 7.33398ZM22.5002 9.83398C30.339 9.83398 36.6668 16.1618 36.6668 24.0007C36.6668 31.8395 30.339 38.1673 22.5002 38.1673C14.6613 38.1673 8.3335 31.8395 8.3335 24.0007C8.3335 16.1618 14.6613 9.83398 22.5002 9.83398ZM21.2306 13.9827C20.8994 13.9879 20.5838 14.1244 20.353 14.3621C20.1223 14.5998 19.9954 14.9194 20.0002 15.2507V24.4173C20.0002 24.7488 20.132 25.0667 20.3664 25.3011L24.533 29.4678C24.6482 29.5877 24.7862 29.6835 24.9388 29.7495C25.0915 29.8155 25.2558 29.8503 25.4221 29.852C25.5884 29.8537 25.7534 29.8222 25.9073 29.7593C26.0613 29.6965 26.2012 29.6035 26.3188 29.4859C26.4364 29.3683 26.5293 29.2285 26.5922 29.0745C26.655 28.9205 26.6866 28.7555 26.6849 28.5892C26.6832 28.4229 26.6483 28.2587 26.5823 28.106C26.5164 27.9533 26.4206 27.8154 26.3006 27.7002L22.5002 23.8997V15.2507C22.5026 15.0833 22.4714 14.9173 22.4084 14.7622C22.3454 14.6072 22.252 14.4664 22.1336 14.3482C22.0152 14.2299 21.8743 14.1367 21.7192 14.0739C21.5641 14.0111 21.3979 13.9801 21.2306 13.9827Z"
            fill="#000"
          />
        </svg>
        <span>
          {startAtStr} - {endAtStr}
        </span>
      </button>
    </div>
  );
};
