import React from "react";

export const NetworkStatusMessage = ({ isOnline }) => {
  const badge = {
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 900,
    width: "100%",
    textAlign: "center",
    color: "white",
    backgroundColor: isOnline ? "green" : "red",
    padding: "12px 0",
  };
  return (
    <div className="top-badge" style={badge}>
      {isOnline ? (
        <div>
          Welcome back. Your internet connection established successfully.
        </div>
      ) : (
        <div>
          Oops..! You are offline. Please check your internet connection. You
          can still continue the assessment.
        </div>
      )}
    </div>
  );
};
