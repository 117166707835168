import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Login,
  GeneratePassword,
  PasswordGenerationMessage,
  ForgotPassword,
  ResetPassword,
  CompleteSignup,
  Dashboard,
  Terms,
  Privacy,
  SessionExpired,
  Faq,
} from ".";
import { QueryClient, QueryClientProvider } from "react-query";
import { ProtectedRoute } from "shared";
import { AppStore } from "store";
import "./styles/supportTicket.styles.css";
import "./styles/errorPage.styles.css";
import { PDFViewer } from "modules";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const resetLinkDialogueData = {
  title: "Check Your Mail!",
  description:
    "We have sent a link to your email ID for creating a new password.",
};

const passwordCreatedDialogueData = {
  title: "Your password has been generated!",
  description: "Please goto login page and signin with your new password.",
};

export const Master = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppStore>
        <BrowserRouter>
          <Routes>
            <Route exact element={<Login />} path={"/"} />
            <Route
              exact
              element={<GeneratePassword />}
              path={"/generatePassword"}
            />
            <Route
              exact
              element={<PasswordGenerationMessage {...resetLinkDialogueData} />}
              path={"/generatedResetLink"}
            />
            <Route
              exact
              element={
                <PasswordGenerationMessage {...passwordCreatedDialogueData} />
              }
              path={"/passwordCreated"}
            />
            <Route
              exact
              element={<ForgotPassword />}
              path={"/forgotPassword"}
            />
            <Route
              exact
              element={<CompleteSignup />}
              path={"/auth/signup/complete"}
            />
            <Route exact element={<Terms />} path={"/terms"} />
            <Route exact element={<Privacy />} path={"/privacy"} />
            <Route element={<Faq />} path={"/faq"} />
            <Route exact element={<ResetPassword />} path={"/auth/reset"} />
            <Route
              exact
              element={
                <ProtectedRoute redirectTo={"/session-expired"}>
                  <Dashboard />
                </ProtectedRoute>
              }
              path={"/home/*"}
            />
            <Route
              exact
              element={<SessionExpired />}
              path={"/session-expired"}
            />
            <Route element={<PDFViewer />} path={"/contents/:dir/:file"} />
            <Route exact element={<Login />} path={"*"} />
          </Routes>
        </BrowserRouter>
      </AppStore>
    </QueryClientProvider>
  );
};
