import { useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LoadingSpinner } from "shared";
import { useAppStore } from "store";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import moment from "moment";

export const CertificatePreviewModal = ({
  isOpen,
  onCancel,
  downloadPdf,
  getCertificate,
  certificateModalState,
}) => {
  const { AppState } = useAppStore();
  const {
    user: { firstName, lastName },
  } = AppState;

  const studentName = `${firstName} ${lastName ? lastName : ""}`;
  const { studentCertificateId, certificateUrl, title, certificationDate } =
    certificateModalState;
  const parsedDateTime = moment(certificationDate);

  const { data: url, isLoading } = getCertificate;
  const generatedCertificateUrl = certificateUrl ? certificateUrl : url;

  useEffect(() => {
    if (isOpen && !certificateUrl) {
      getCertificate.mutate(studentCertificateId);
    }
  }, [isOpen, certificateUrl]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);

  const loadingPDF = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "70vh" }}
    >
      Loading PDF…
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      size={"lg"}
      scrollable={true}
      className="certificate-preview-modal"
    >
      <ModalHeader toggle={onCancel}>
        <div className="modal-title text-dark">Certificate</div>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center pb-3">
        <div className="container">
          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <>
              {generatedCertificateUrl ? (
                <>
                  <Document
                    file={generatedCertificateUrl}
                    className="d-flex justify-content-center"
                    loading={loadingPDF}
                  >
                    <Page
                      pageNumber={1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Document>
                </>
              ) : (
                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  Certificate not found!
                </span>
              )}
            </>
          )}
        </div>
      </ModalBody>
      {generatedCertificateUrl && (
        <ModalFooter style={{ justifyContent: "center" }}>
          <div>
            <div className="certificate-modal-text text-black ">
              <strong>{studentName}</strong> has successfully received{" "}
              <strong>{title}</strong>{" "}
              {parsedDateTime
                ? `on ${parsedDateTime.format("DD MMM YYYY hh:mm A")}`
                : ""}
              .
            </div>
            <div className="certificate-actions ">
              <center>
                <div>
                  <button
                    disabled={isLoading}
                    className="certificate-action-btn c-a-b-sm"
                    onClick={() => downloadPdf(generatedCertificateUrl, title)}
                  >
                    <i className="bi-download"></i>
                  </button>
                </div>
              </center>
            </div>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};
