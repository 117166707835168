import React from "react";
import parse from "html-react-parser";
import { LoadingSpinner } from "shared";
import { CourseContent } from "../containers";

export const TasksContent = ({ taskDetailsInfo }) => {
  if (taskDetailsInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const { title, description, taskGroup, taskSections, taskContents } =
    taskDetailsInfo?.data;

  const isTheory = taskGroup === 1;
  const isVideo = taskGroup === 2;
  const isDocument = taskGroup === 3;

  return (
    <div className="tasks-content">
      <h2>{title}</h2>
      {description && <div>{parse(description)}</div>}
      {isTheory &&
        taskSections?.length > 0 &&
        taskSections?.map((elem) => {
          return (
            <div className="card rounded border mb-4 w-100">
              <div
                className="card-header border-bottom py-3"
                style={{ backgroundColor: "white" }}
              >
                <h5 className="mb-0 text-dark">{elem?.title || ""}</h5>
              </div>
              <div className="card-body py-3">
                <div>{elem?.description && parse(elem?.description)}</div>
                <div className="d-flex justify-content-start flex-wrap">
                  {elem?.sectionContents?.map((content, index) => {
                    return (
                      <CourseContent
                        key={`CourseContent${index}`}
                        content={content}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      {isVideo && (
        <div style={{ maxWidth: "1000px" }}>
          {taskContents?.length > 0 &&
            taskContents?.map((elem) => {
              if (elem?.contentTypeStr === "Video") {
                return (
                  <div className="iframe-container mb-4">
                    <iframe className="responsive-iframe" src={elem?.fileURL} />
                  </div>
                );
              } else {
                return (
                  <div className="iframe-container mb-4">
                    <video
                      controls
                      controlsList="nodownload"
                      disablePictureInPicture
                      className="responsive-iframe"
                    >
                      <source src={elem?.fileURL} />
                    </video>
                  </div>
                );
              }
            })}
        </div>
      )}
      {isDocument &&
        taskContents?.length > 0 &&
        taskContents?.map((elem, index) => {
          let lastElemt = index + 1 === taskContents.length;
          if (elem?.contentType === 1) {
            var dirArray = elem?.fileURL.split("/");
            let dir = dirArray[dirArray.length - 2];
            let fileNames = dirArray[dirArray.length - 1];
            let filePath = `${dir}/${fileNames}`;
            return (
              <div className={lastElemt ? "" : "mb-5"}>
                <iframe
                  className="w-100"
                  style={{ height: "100dvh" }}
                  src={`/contents/${filePath}`}
                />
              </div>
            );
          } else {
            return (
              <div className={lastElemt ? "" : "mb-5"}>
                <iframe
                  className="w-100"
                  style={{ height: "100dvh" }}
                  src={elem?.fileURL}
                />
              </div>
            );
          }
        })}
    </div>
  );
};
