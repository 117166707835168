import React, { useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Avatar from "assets/images/avatar-empty.png";
import CertificateIcon from "assets/images/certificates.svg";
import { useImmer } from "use-immer";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAppStore } from "store";
import { AssessmentTimer } from "modules";
import { useQuestionareContext, useAuthenticate } from "master";

export const HeaderRightItems = () => {
  const questionnaireContext = useQuestionareContext();
  const {
    startAssessmentQuery,
    onToggleInstruction,
    onToggleHelp,
    questionnaire,
  } = questionnaireContext;
  const { onUserSignOut } = useAuthenticate();

  const [isDropDown, setIsDropDown] = useImmer(false);
  const { AppState } = useAppStore();

  const {
    user: { firstName, lastName },
  } = AppState;

  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = useCallback(() => {
    setIsDropDown((state) => !state);
  }, [isDropDown]);

  const logout = () => {
    onUserSignOut();
  };

  const isQuestionnairePages =
    location.pathname === "/home/assessment/start" ||
    location.pathname === "/home/assessment/review";

  return (
    <ul className="header-right-items-list mb-0 ps-0 d-flex justify-contents-start align-items-center">
      {isQuestionnairePages ? (
        <>
          <AssessmentTimer
            validTill={
              startAssessmentQuery?.data?.validTill || questionnaire?.validTill
            }
            currentTime={
              startAssessmentQuery?.data?.currentSystemTime ||
              questionnaire?.currentSystemTime
            }
          />
          <li className="header-item me-4">
            <button
              onClick={() => onToggleInstruction(null, "header")}
              className="assessment-main-header-button"
            >
              <div className="assessment-header-action">
                <span className="header-action-icon action-icon-instruction"></span>
                <span className="header-action-txt">Instructions</span>
              </div>
            </button>
          </li>
          <li className="header-item me-3">
            <button
              onClick={() => onToggleHelp()}
              className="assessment-main-header-button"
            >
              <div className="assessment-header-action">
                <span className="header-action-icon action-icon-help"></span>
                <span className="header-action-txt">Help</span>
              </div>
            </button>
          </li>
          <li className="header-item ms-4">
            <div className="user-menu d-flex assessment-header-action">
              <div className="user-img d-flex align-items-center">
                <div className="avatar">
                  <img
                    src={Avatar}
                    className="rounded-circle"
                    height="34"
                    alt="Avatar"
                  />
                </div>
                <span className="header-action-txt ms-2">
                  {firstName} {lastName}
                </span>
              </div>
            </div>
          </li>
        </>
      ) : (
        <>
          <li className="header-item">
            <Link
              to="/home/notifications"
              className="header-notification-btn header-link"
            >
              <i className="bi bi-bell-fill"></i>
            </Link>
          </li>
          <li className="header-item ms-4">
            <Dropdown
              className="dropdown"
              isOpen={isDropDown}
              toggle={toggleDropdown}
            >
              <DropdownToggle tag="a">
                <div className="user-menu d-flex">
                  <div className="user-img d-flex align-items-center">
                    <div className="avatar">
                      <img
                        src={Avatar}
                        className="rounded-circle"
                        height="34"
                        alt="Avatar"
                      />
                    </div>
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu end={true}>
                <DropdownItem header>Hello, {firstName}!</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => navigate("/home/profile")}>
                  <i className="icon-mid bi bi-person-circle me-2" /> Profile
                </DropdownItem>
                <DropdownItem onClick={() => navigate("/home/my-certificates")}>
                  <img
                    src={CertificateIcon}
                    className="me-2"
                    height={16}
                    width={19}
                  />
                  Certificate
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="icon-mid bi bi-box-arrow-left me-2" /> Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
        </>
      )}
    </ul>
  );
};
