import { ProgressBar } from "shared";
import DefaultThumbnail from "assets/images/default-vidya-thumbnail-image.svg";

export const Course = ({
  imageUrl,
  name,
  overallPercentage,
  courseType,
  totalDuration,
  ...rest
}) => {
  const isOjt = courseType === 2;
  const isRegular = courseType === 0;
  const thumbnail = imageUrl ? imageUrl : DefaultThumbnail;

  return (
    <div
      {...rest}
      className="col-lg-3 col-6 mb-4 course-tiles"
      style={{ cursor: "pointer" }}
    >
      <div className="card pt-5 pb-5 px-4 text-center course-tile-card">
        <img
          src={thumbnail}
          alt=""
          className="ms-auto me-auto mb-3"
          style={{ height: "80px", width: "80px" }}
        />
        <p className="mb-0 text-dark tile-title">{name}</p>
        {!isRegular && <ProgressBar value={overallPercentage} />}
        {isOjt && totalDuration !== null && (
          <div className="progress-hrs">{totalDuration} Min</div>
        )}
      </div>
    </div>
  );
};
