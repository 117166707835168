import { PageLayout } from "layouts";
import { useProgramHelp } from "..";
import { LoadingSpinner } from "shared";
import { useImmer } from "use-immer";
import parse from "html-react-parser";
import "./styles/help.styles.css";

export const Help = () => {
  const [activeTabIndex, setActiveTabIndex] = useImmer(0);

  const localStorageProgram = JSON.parse(
    localStorage.getItem("selectedProgram")
  );

  const { id: programId, name: programName } = localStorageProgram;
  const { programHelpQuery } = useProgramHelp({ load: true, programId });
  const { isLoading, isFetching, data: programHelp } = programHelpQuery;

  const titles =
    programHelp &&
    programHelp?.map((tab, i) => {
      return (
        <li
          key={i}
          className={`help-menu-item ${i === activeTabIndex ? "active" : ""}`}
          onClick={() => setActiveTabIndex(i)}
        >
          {tab?.name}
        </li>
      );
    });

  const contents =
    programHelp &&
    programHelp
      ?.filter((tab, i) => i === activeTabIndex)
      .map((tab, i) => {
        return (
          <div className="content-wrapper ql-editor p-0" key={i}>
            {tab?.content && parse(tab?.content)}
          </div>
        );
      });

  if (isLoading || isFetching) return <LoadingSpinner />;

  return (
    <PageLayout
      breadcrumb={[
        { label: programName ? programName : "Home", location: "/home" },
        { label: "Need Help" },
      ]}
    >
      {programHelp?.length > 0 ? (
        <div className="help-page-wrapper">
          <div className="help-sidebar">
            <ul>{titles}</ul>
          </div>
          <div className="help-content">{contents}</div>
        </div>
      ) : (
        <div className="help-empty-message">
          <h3>No help pages found in {programName}</h3>
        </div>
      )}
    </PageLayout>
  );
};
