import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import parse from "html-react-parser";

export const HelpModal = ({ isOpen, onCancel, data, ...rest }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} size={"lg"} {...rest}>
      <ModalHeader toggle={onCancel}>Help</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="assessment-modal-infos">
              <div className="modal-info-content">{data && parse(data)}</div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
