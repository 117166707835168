import React from "react";
import { Navigate } from "react-router-dom";
import { useAppStore } from "store";

export const ProtectedRoute = ({ children, redirectTo }) => {
  const {
    AppState: { token },
  } = useAppStore();

  return token ? children : <Navigate to={redirectTo} />;
};
