import VideoIcon from "assets/images/video-icon.svg";

export const VideoContent = ({ label, redirectTo, onSetUrl }) => {
  return (
    <div
      onClick={(e) => onSetUrl(redirectTo, label)}
      className="chip-button border ps-3 pe-3 pt-2 pb-2 me-3 mb-3 d-flex rounded-pill align-items-center"
      style={{ cursor: "pointer" }}
    >
      <img src={VideoIcon} alt="icon" className="me-2" />
      <span>{label}</span>
    </div>
  );
};
