import { useImmer } from "use-immer";
import { getForgotPassword } from "..";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorFormatter } from "shared";

export const useForgotPassword = () => {
  const navigate = useNavigate();

  const [forgotPassEmail, setForgotPassEmail] = useImmer({
    email: "",
  });

  const forgotPasswordAuth = useMutation(getForgotPassword, {
    onSuccess: (data) => {
      navigate("/generatedResetLink", { replace: true });
    },
    onError: (data) => {
      errorFormatter(data);
    },
    onSettled: () => {},
  });

  return {
    forgotPassEmail,
    setForgotPassEmail,
    forgotPasswordAuth,
  };
};
