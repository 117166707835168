import { useCallback } from "react";
import CourseIcon from "assets/images/course.svg";
import PracticalsIcon from "assets/images/practicals.svg";
import OjtIcon from "assets/images/OJT.svg";
import CertificateIcon from "assets/images/certificates.svg";
import AssessmentIcon from "assets/images/assessment-icon.svg";
import HelpIcon from "assets/images/help-icon.svg";
import { useAppStore } from "store";
import {
  useProgram,
  SideBarWidget,
  NotificationWidget,
  ProgramTile,
  TermsModal,
} from ".";
import { LoadingSpinner } from "shared";

export const DashboardView = () => {
  const { AppState } = useAppStore();
  const {
    user: { firstName },
  } = AppState;

  const {
    myProgramsQuery,
    program,
    termsModal,
    setProgram,
    onAcceptTerms,
    onClickProgramItems,
  } = useProgram({
    load: true,
  });

  const onSelectProgram = useCallback(
    (id) => {
      const selectedProgram = myProgramsQuery.data.find((p) => p.id === id);
      if (selectedProgram) {
        window.history.replaceState(null, null, window.location.pathname);
        localStorage.setItem(
          "selectedProgram",
          JSON.stringify(selectedProgram)
        );
        setProgram(selectedProgram);
      }
    },
    [program]
  );

  if (myProgramsQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <section className="section">
        <div className="container-md">
          <div className="row pt-3 pb-3">
            <div className="col-md-8">
              {program ? (
                <>
                  <div className="page-title">
                    <h1
                      style={{ color: "#00438B", fontSize: "24px" }}
                      className="mb-4 mt-3"
                    >
                      Welcome back, {firstName} !
                    </h1>
                  </div>
                  <div className="page-content">
                    <div className="card dashboard-item-card">
                      <div
                        className="card-header"
                        style={{ backgroundColor: "#00438B" }}
                      >
                        <div className="d-flex col-12 justify-content-between align-items-center pt-2 pb-2">
                          <h5 className="mb-0 text-white">{program.name}</h5>
                          {myProgramsQuery.data.length >= 2 && (
                            <div className="dropdown">
                              <button
                                className="btn dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{
                                  backgroundColor: "#00438B",
                                  color: "white",
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                Change Program
                              </button>
                              <ul className="dropdown-menu">
                                {myProgramsQuery.data.map((p, i) => {
                                  return (
                                    <li key={p.id}>
                                      <button
                                        onClick={() => onSelectProgram(p.id)}
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        {p.name}
                                      </button>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="card-body border-0">
                        <div className="row" style={{ marginBottom: "-20px" }}>
                          <ProgramTile
                            title="Learning Materials"
                            icon={CourseIcon}
                            onClick={() =>
                              onClickProgramItems("/home/courses/regular")
                            }
                          />
                          {program?.hasPracticals && (
                            <ProgramTile
                              title="Practicals"
                              icon={PracticalsIcon}
                              onClick={() =>
                                onClickProgramItems("/home/courses/practicals")
                              }
                            />
                          )}
                          {program?.hasOJT && (
                            <ProgramTile
                              title="Assignment"
                              icon={OjtIcon}
                              onClick={() =>
                                onClickProgramItems("/home/courses/ojt")
                              }
                            />
                          )}
                          {program?.hasAssessment && (
                            <ProgramTile
                              title="Assessment"
                              icon={AssessmentIcon}
                              onClick={() =>
                                onClickProgramItems("/home/assessment")
                              }
                            />
                          )}
                          {program?.hasCertificate && (
                            <ProgramTile
                              title="My Certifications"
                              icon={CertificateIcon}
                              onClick={() =>
                                onClickProgramItems("/home/certificates")
                              }
                            />
                          )}
                          {program?.hasHelp && (
                            <ProgramTile
                              title="Help"
                              icon={HelpIcon}
                              onClick={() => onClickProgramItems("/home/help")}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center mt-4">
                  <h6>No programs found!</h6>
                </div>
              )}
            </div>

            <SideBarWidget>
              <NotificationWidget title="Notifications" />
            </SideBarWidget>
          </div>
          {program && program?.logoImageNameUrl && (
            <div className="row">
              <div className="col-12 mt-5 pt-5 mb-5">
                <div
                  className="card m-auto text-center p-3 rounded-3"
                  style={{ maxWidth: "370px" }}
                >
                  <h6>In Association With</h6>
                  <img
                    className="ms-auto me-auto mt-2 home-association-logo"
                    src={program?.logoImageNameUrl}
                    alt={program?.logoImageName}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <TermsModal
          isOpen={termsModal.isOpen}
          message={termsModal.content}
          title={termsModal.title}
          onConfirm={onAcceptTerms}
        />
      </section>
    </>
  );
};
