import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import "./assets/fonts/bootstrap-icons/bootstrap-icons.css";
import { Master } from "./master";

const hostname = window.location.hostname;
const isLocal =
  hostname === "localhost" ||
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(hostname);

if (!isLocal) {
  Sentry.init({
    dsn: "https://b8325a8c42354ea98e0aad184caeb378@o4504909936001024.ingest.sentry.io/4504915457736704",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIORNMENT_KEY,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Master />
  </React.StrictMode>
);
