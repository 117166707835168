import React, { useCallback } from "react";
import { Spinner } from "reactstrap";

export const TaskFooterNavigation = ({
  onNextTask,
  onPrevTask,
  currentTask,
  courseType,
  onStartSimulation,
  taskControl,
  taskDetailsInfo,
  updateCourseTasksStatus,
  isTaskDetailsLoading = true,
}) => {
  const { taskGroup } = !taskDetailsInfo.isLoading && taskDetailsInfo?.data;
  const { studentTaskStatus, id } = currentTask;

  const isOjt = courseType === 2;
  const isSimulation = taskGroup === 0;
  const completed = studentTaskStatus === 2;
  const isOjtCompleted = isOjt && completed;
  const isCourseTaskComlpeteExeptSimulation = completed && !isSimulation;

  const getStatus = useCallback(
    (status) => {
      switch (status) {
        case 0:
          return isSimulation ? "Start" : "Mark as complete";
        case 1:
          return isSimulation ? "Continue" : "Mark as complete";
        case 2:
          return isOjt ? (
            "Completed"
          ) : !isSimulation ? (
            <span>
              Completed{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
                style={{ marginTop: "-3px" }}
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </span>
          ) : (
            "Re-Do Task"
          );
        default:
          return "";
      }
    },
    [studentTaskStatus, isSimulation]
  );

  const updateCourseTaskStatusToComplete = () => {
    updateCourseTasksStatus.mutate({ studentTaskId: id, taskStatus: 2 });
  };

  const nextTaskIndex = taskControl.currentIndex + 1;
  const prevTaskIndex = taskControl.currentIndex - 1;
  const isFirstTask = prevTaskIndex < 0;
  const isLastTask = nextTaskIndex >= taskControl.totalTasks;
  const hasTasks = taskControl.totalTasks > 1;

  const isButtonDisabled = updateCourseTasksStatus?.isLoading;

  return (
    <div className="tasks-content-footer pt-2 d-flex justify-content-between align-items-center">
      {taskDetailsInfo?.isLoading && !isOjt ? (
        <></>
      ) : (
        <>
          <div className="task-status-block">
            <div className="tasks-content-action">
              {!isTaskDetailsLoading && (
                <>
                  {!isOjtCompleted && !isCourseTaskComlpeteExeptSimulation && (
                    <button
                      style={{ minWidth: "128px" }}
                      onClick={
                        isSimulation
                          ? onStartSimulation
                          : updateCourseTaskStatusToComplete
                      }
                      className="task-action-btn"
                      disabled={isButtonDisabled}
                    >
                      {isButtonDisabled ? (
                        <Spinner color="light" size="sm">
                          Loading...
                        </Spinner>
                      ) : (
                        getStatus(studentTaskStatus)
                      )}
                    </button>
                  )}
                  {isOjtCompleted && (
                    <button
                      className="task-action-completed-btn"
                      style={{ minWidth: "128px" }}
                      disabled={isButtonDisabled}
                    >
                      <span className="completed-icon"></span>
                      <span>
                        {isButtonDisabled ? (
                          <Spinner color="light" size="sm">
                            Loading...
                          </Spinner>
                        ) : (
                          getStatus(studentTaskStatus)
                        )}
                      </span>
                    </button>
                  )}
                  {isCourseTaskComlpeteExeptSimulation && (
                    <button
                      style={{ minWidth: "128px" }}
                      className="task-action-btn"
                    >
                      {getStatus(studentTaskStatus)}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {hasTasks && (
            <div className="task-nav-btns d-flex align-items-center">
              <button
                onClick={onPrevTask}
                className="task-navigate-btn task-action-navigate d-flex align-items-center task-action-prev-btn"
                disabled={isFirstTask}
              >
                <i className="bi-chevron-left"></i>
                <span>Previous</span>
              </button>
              <button
                onClick={onNextTask}
                className="task-navigate-btn task-action-navigate d-flex align-items-center task-action-next-btn"
                disabled={isLastTask}
              >
                <span>Next</span>
                <i className="bi-chevron-right"></i>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
