import React from "react";
import { useQuery } from "react-query";
import { getSettingsById, GET_SETTINGS_BY_ID_API } from "..";

export const useSettings = ({ load = false, key = null }) => {
  const settingsInfo = useQuery(
    [GET_SETTINGS_BY_ID_API, key],
    () => getSettingsById(key),
    {
      enabled: load && !!key,
    }
  );

  return {
    settingsInfo,
  };
};
