import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";

export const SlotUnsuccessfullModal = ({
  isOpen,
  onCancel,
  onConfirm,
  data,
  ...rest
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} size={"md"} {...rest}>
      <ModalHeader toggle={onCancel}>Schedule Assessment</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="assessment-modal-layout">
              <div className="modal-md-icon info-fill-icon"></div>
              <h3 className="modal-head-gray">{data?.message}!</h3>
              <h4>
                {moment(data?.scheduledDate, "DD-MMM-YYYY").format(
                  "MMMM DD YYYY"
                )}
                <br />
                {data?.startAtStr} - {data?.endAtStr}
              </h4>
              <div className="modal-btn-group">
                <button
                  onClick={onConfirm}
                  className="assessment-md-btn px-5 tile-btn-blue"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
