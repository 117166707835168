import React from "react";

export const QuestionLegend = ({ type }) => {
  switch (type) {
    case "completed":
      return (
        <div className="question-legends-item completed">
          <span className="legend-indicator"></span>
          <span className="legend-text">Completed</span>
        </div>
      );
    case "marked":
      return (
        <div className="question-legends-item marked">
          <span className="legend-indicator"></span>
          <span className="legend-text">Marked</span>
        </div>
      );
    case "current":
      return (
        <div className="question-legends-item current">
          <span className="legend-indicator"></span>
          <span className="legend-text">Current</span>
        </div>
      );
    case "toAttend":
      return (
        <div className="question-legends-item">
          <span className="legend-indicator"></span>
          <span className="legend-text">Yet to attend</span>
        </div>
      );
    default:
      return (
        <div className="question-legends-item">
          <span className="legend-indicator"></span>
          <span className="legend-text">Yet to attend</span>
        </div>
      );
  }
};
