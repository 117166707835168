import { Axios } from "utils";

export const getQuestionnaire = async ({ assessmentId, slotId, attemptId }) => {
  const questionnaireParameters = attemptId
    ? `AssessmentId=${assessmentId}&SlotId=${slotId}&AttemptId=${attemptId}`
    : `AssessmentId=${assessmentId}&SlotId=${slotId}`;

  const res = await Axios.get(
    `/Assessments/student/start?${questionnaireParameters}`
  );
  return res.data;
};

export const answerAssessment = async (payload) => {
  const res = await Axios.post(`/Assessments/student/answer`, payload);
  return res.data;
};

export const deleteAnswer = async (answerId) => {
  const res = await Axios.delete(`/Assessments/student/answer/${answerId}`);
  return res.data;
};

export const submitAnswers = async ({ assessmentMasterId, payload }) => {
  const res = await Axios.put(
    `/Assessments/student/end/${assessmentMasterId}`,
    payload
  );
  return res.data;
};
