import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PageLayout } from "layouts";
import { useCourses, Course } from "..";
import { LoadingSpinner, EmptyMessage } from "shared";

export const CourseList = () => {
  const history = useNavigate();
  const location = useLocation();

  const selectedProgram = JSON.parse(localStorage.getItem("selectedProgram"));
  const { courseType } = useParams();

  const { courseQuery, selectedCourseType } = useCourses({
    programId: selectedProgram.id,
    studentBatchId: selectedProgram.studentBatchId,
    type: courseType,
    courseId: 0,
    load: true,
  });

  const openDetails = (cId) => {
    history(`${location.pathname}/${cId}`);
  };

  const breadcrumb = [
    {
      label: selectedProgram?.name ? selectedProgram.name : "Home",
      location: "/home",
    },
    { label: selectedCourseType?.title },
  ];

  if (courseQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {courseQuery && (
        <PageLayout title={selectedCourseType?.title} breadcrumb={breadcrumb}>
          {courseQuery?.data?.length > 0 && (
            <div className="row mb-5">
              {courseQuery.data.map((cname, index) => (
                <Course
                  key={index}
                  name={cname.name}
                  imageUrl={cname.courseImageURL}
                  overallPercentage={cname.overallPercentage}
                  totalDuration={cname.totalDuration}
                  courseType={selectedCourseType?.id}
                  onClick={() => openDetails(cname.courseId)}
                />
              ))}
            </div>
          )}
          {courseQuery?.data?.length === 0 && (
            <EmptyMessage
              heading={"No courses found!"}
              subHeading={`There is no ${selectedCourseType?.title} assigned for you at the moment. Please check back later.`}
              image={selectedCourseType?.icon}
              onCancel={() => history(`/home`)}
            />
          )}
        </PageLayout>
      )}
    </>
  );
};
