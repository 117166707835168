import { useState, useRef, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FormFeedback, Spinner } from "reactstrap";
import { useChangePassword } from "..";
import SimpleReactValidator from "simple-react-validator";
import { PasswordInput } from "shared";
import { strongPassword } from "utils";

export const ChangePasswordModal = ({ size, isOpen, title, onCancel }) => {
  const [update, forceUpdate] = useState();
  const {
    changePasswordPayload,
    setChangePasswordPayload,
    changePasswordAuth,
  } = useChangePassword();

  const { mutate, isLoading } = changePasswordAuth;
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: strongPassword,
    })
  );

  useEffect(() => {
    setChangePasswordPayload((draft) => {
      draft.currentPassword = "";
      draft.newPassword = "";
      draft.confirmPassword = "";
      return draft;
    });
    validator.current.hideMessages();
  }, [isOpen]);

  const onsubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      mutate(changePasswordPayload);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} size={size} centered={true}>
      <ModalHeader toggle={onCancel} style={{ borderBottom: "none" }}>
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <form>
            <div className="form-group position-relative mb-4 has-icon-right">
              <label htmlFor="first-cpass-vertical" className="mb-2">
                Current Password
              </label>
              <PasswordInput
                name="currentPassword"
                className="form-control-lg form-control"
                placeholder="Current password"
                value={changePasswordPayload.currentPassword}
                onChange={(e) => {
                  setChangePasswordPayload((draft) => {
                    draft.currentPassword = e.target.value;
                  });
                }}
                invalid={validator.current.message(
                  "currentPassword",
                  changePasswordPayload.currentPassword,
                  "required"
                )}
              />
              <FormFeedback>
                {validator.current.message(
                  "currentPassword",
                  changePasswordPayload.currentPassword,
                  "required"
                )}
              </FormFeedback>
            </div>
            <div className="form-group position-relative mb-4 has-icon-right">
              <label htmlFor="first-new-password-vertical" className="mb-2">
                New Password
              </label>
              <PasswordInput
                name="newPassword"
                className="form-control-lg form-control"
                placeholder="New password"
                value={changePasswordPayload.newPassword}
                onChange={(e) => {
                  setChangePasswordPayload((draft) => {
                    draft.newPassword = e.target.value;
                  });
                }}
                invalid={validator.current.message(
                  "newPassword",
                  changePasswordPayload.newPassword,
                  "required|min:8|upper|lower|digit|special"
                )}
              />
              <FormFeedback>
                {validator.current.message(
                  "newPassword",
                  changePasswordPayload.newPassword,
                  "required|min:8|upper|lower|digit|special"
                )}
              </FormFeedback>
            </div>
            <div className="form-group position-relative mb-4 has-icon-right">
              <label htmlFor="first-confirm-password-vertical" className="mb-2">
                Confirm Password
              </label>
              <PasswordInput
                name="confirmPassword"
                className="form-control-lg form-control"
                placeholder="Confirm Password"
                value={changePasswordPayload.confirmPassword}
                onChange={(e) => {
                  setChangePasswordPayload((draft) => {
                    draft.confirmPassword = e.target.value;
                  });
                }}
                invalid={validator.current.message(
                  "confirmPassword",
                  changePasswordPayload.confirmPassword,
                  "required"
                )}
              />
              {changePasswordPayload.confirmPassword &&
                changePasswordPayload.confirmPassword !==
                  changePasswordPayload.newPassword && (
                  <div
                    style={{ fontSize: "14px", marginTop: "2px" }}
                    className="text-danger"
                  >
                    Password must be same!
                  </div>
                )}
              <FormFeedback>
                {validator.current.message(
                  "confirmPassword",
                  changePasswordPayload.confirmPassword,
                  "required"
                )}
              </FormFeedback>
            </div>
            <button
              className="btn btn-block btn-lg shadow-lg mt-1 ni-btn-primary mb-2"
              disabled={isLoading}
              onClick={(e) => {
                onsubmit(e);
              }}
            >
              {isLoading ? <Spinner type="border" color="light" /> : "Reset"}
            </button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
