import React, { useEffect } from "react";

import { useCountdown } from "master";
import { useQuestionareContext } from "master";

export const AssessmentTimer = ({ validTill, currentTime }) => {
  const questionnaireContext = useQuestionareContext();
  const { onTimeOut } = questionnaireContext;

  const { timer } = useCountdown({
    validTill,
    currentTime,
  });
  const { hours, minutes, seconds } = timer;

  useEffect(() => {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      onTimeOut();
    }
  }, [hours, minutes, seconds]);

  const formatTimeDigit = (value) => {
    if (value) {
      return value < 10 ? "0" + value : value;
    } else {
      return "00";
    }
  };

  return (
    <li className="header-item me-5">
      <div className="assessment-countdown-timer">
        <span>{formatTimeDigit(hours)}</span>:
        <span>{formatTimeDigit(minutes)}</span>:
        <span>{formatTimeDigit(seconds)}</span>
      </div>
    </li>
  );
};
