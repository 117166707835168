import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { getCookie } from "utils";

export const getTokens = () => {
  var localTokens = JSON.parse(getCookie("_stu_user_data"));
  return localTokens;
};

export const clearLocalKeysWithPrefix = (
  prefix = "",
  storageType = "localStorage"
) => {
  if (prefix !== "") {
    let keys = "";

    if (storageType === "sessionStorage") {
      keys = Object.keys(sessionStorage);
    } else if (storageType === "localStorage") {
      keys = Object.keys(localStorage);
    }

    const regex = new RegExp(`^${prefix}`);

    for (const key of keys) {
      if (regex.test(key)) {
        if (storageType === "localStorage") {
          localStorage.removeItem(key);
        } else if (storageType === "sessionStorage") {
          sessionStorage.removeItem(key);
        }
      }
    }
  }
};

export const trimText = (str, length = 35, tail = "...") => {
  if (str?.length > length) {
    return str.substring(0, length - tail.length) + tail;
  } else {
    return str;
  }
};

export const trimHtmlContent = (htmlContent, lettersCount) => {
  const regex = /(<([^>]+)>)/gi;
  let convertedHtml = htmlContent.replace(regex, "");
  return trimText(convertedHtml, lettersCount);
};

export const errorMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    backgroundColor: "#f93e3e",
  }).showToast();
};

export const successMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Done!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    backgroundColor: "#4fbe87",
  }).showToast();
};
