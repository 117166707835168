import React, { useEffect, useRef } from "react";
import moment from "moment";
import { useImmer } from "use-immer";

export const useTimeout = ({ currentTime, startTime }) => {
  const intervalRef = useRef();

  const currTime = moment(currentTime).format("YYYY-MM-DDTHH:mm:ss");
  const startAt = moment(startTime).format("YYYY-MM-DDTHH:mm:ss");
  const start = new Date(startAt);
  const current = new Date(currTime);

  const [timer, setTimer] = useImmer(start - current);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((draft) => {
        if (isNaN(draft) || draft <= 0) {
          draft = start - current;
        } else {
          draft = draft - 1000;
        }
        return draft;
      });
    }, 1000);
    intervalRef.current = interval;
    return () => clearInterval(intervalRef.current);
  }, [currentTime, startTime]);

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  return {
    timer,
  };
};
