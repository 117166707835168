import { useImmer } from "use-immer";
import { completeSignup } from "..";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorFormatter } from "shared";

export const useCompleteSignup = () => {
  const navigate = useNavigate();

  const [resetPassPayload, setResetPassPayload] = useImmer({
    password: "",
    confirmPassword: "",
  });

  const resetPasswordAuth = useMutation(completeSignup, {
    onSuccess: () => {
      navigate("/passwordCreated", { replace: true });
    },
    onError: (data) => {
      errorFormatter(data);
    },
  });

  return {
    resetPassPayload,
    setResetPassPayload,
    resetPasswordAuth,
  };
};
