import "../styles/emptyMessage.style.css";

export const EmptyMessage = ({ heading, subHeading, image, onCancel }) => {
  return (
    <div className="text-center pb-5 pt-5">
      {image && (
        <div className="empty-msg-icon rounded-1 rounded-circle d-flex justify-content-center align-items-center ms-auto me-auto mb-4">
          {image && <img src={image} alt="" />}
        </div>
      )}
      <h3 className="mb-3 empty-msg-title">{heading}</h3>
      <p className="ms-auto me-auto empty-msg-description">{subHeading}</p>
      {onCancel && (
        <button className="task-action-btn empty-msg-action" onClick={onCancel}>
          Go back
        </button>
      )}
    </div>
  );
};
