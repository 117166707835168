import React from "react";
import parse from "html-react-parser";
import { useImmer } from "use-immer";
import { QuestionStatusBadge } from "..";

export const QuestionModalItem = ({
  question,
  index,
  navigateQuestionHandle,
  markedQuestions,
}) => {
  const { description, choices, answer, sectionId } = question;
  const [showMore, isShowMore] = useImmer(false);

  return (
    <div className="question-preview-item">
      <div className="question-preview-content">
        <QuestionStatusBadge
          index={index}
          question={question}
          markedQuestions={markedQuestions}
        />
        <div className="modal-preview-content">
          <div className="assessment-question">
            <p>{description && parse(description)}</p>
          </div>
          {showMore && (
            <div className="assessment-question-choices">
              <ul className="assessment-question-choice-list">
                {choices?.map((q) => {
                  return (
                    <li
                      key={q.choiceId}
                      className="assessment-question-choice disabled-choice"
                    >
                      <div className="custom-radio-button">
                        <input
                          type="radio"
                          id={`modal-choice-${q.choiceId}`}
                          name={`modal-choice_${q.questionId}`}
                          value={q.choiceId}
                          disabled={true}
                          checked={q.choiceId === answer?.choiceId}
                        />
                        <label htmlFor={`modal-choice-${q.choiceId}`}>
                          {q.code}
                          {")"}. {q.description}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <button
          onClick={() =>
            navigateQuestionHandle(question?.questionNumber, sectionId)
          }
          className="question-preview-nav-btn"
        ></button>
      </div>
      <div className="question-preview-modal-footer">
        <div className="question-preview-expand">
          <button onClick={() => isShowMore(!showMore)} className="expand-btn">
            <span>{showMore ? "Show less" : "Show more"}</span>
            <span
              className={`bi expand-btn-icon ${
                showMore ? "bi-chevron-up" : "bi-chevron-down"
              }`}
            ></span>
          </button>
        </div>
      </div>
    </div>
  );
};
