import { GET_STUDENT_SPECIFIC_DATA, getStudentSpecificData } from "..";
import { useQuery } from "react-query";

export const useStudentSpecificDataQuery = ({
  load = false,
  programId = "",
  courseId = null,
}) => {
  return useQuery(
    `${GET_STUDENT_SPECIFIC_DATA}_${programId}_${courseId}`,
    () => getStudentSpecificData(programId, courseId),
    {
      enabled: load && programId !== "" && courseId !== null,
    }
  );
};
