import React from "react";
import {
  AssessmentList,
  Questionnaire,
  Review,
  AssessmentResult,
  ExpiredPage,
} from "..";
import { Routes, Route } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import "./styles/assessment.styles.css";
import "./styles/assessmentReview.styles.css";
import "./styles/assessmentQuestions.styles.css";
import "./styles/assessmentResult.styles.css";
import "./styles/assessmentExpired.styles.css";

export const Assessment = () => {
  return (
    <Routes>
      <Route path="/" element={<AssessmentList />} />
      <Route path="/start" element={<Questionnaire />} />
      <Route path="/review" element={<Review />} />
      <Route path="/result" element={<AssessmentResult />} />
      <Route path="/expired" element={<ExpiredPage />} />
    </Routes>
  );
};
