import NotificationIcon from "assets/images/notification.svg";
import { Link } from "react-router-dom";
import { useNotification } from "modules";
import { LoadingSpinner, NotificationItem } from "shared";

export const NotificationWidget = ({ title }) => {
  const { notificationsQuery } = useNotification({
    load: true,
  });

  const { data, isLoading } = notificationsQuery;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="card sidebar-notification-block mb-3">
      <div className="card-body">
        <h5 className="card-title mb-4">{title}</h5>
        {data?.length > 0 ? (
          <div className="sidebar-notification">
            <div>
              {!isLoading &&
                data
                  ?.slice(0, 4)
                  .map((notification) => (
                    <NotificationItem
                      key={notification?.id}
                      data={notification}
                      url={"/home/notifications"}
                    />
                  ))}
            </div>
            <div className="mt-3">
              <Link to="/home/notifications" className="notification-show-btn">
                View all notification
              </Link>
            </div>
          </div>
        ) : (
          <div className="text-center pt-5 pb-5 ps-3 pe-3">
            <div className="notification-empty-info rounded-1 rounded-circle d-flex justify-content-center align-items-center ms-auto me-auto mb-4">
              <img src={NotificationIcon} alt="empty notification" />
            </div>
            <p className="text-black-50">
              <strong>No notifications yet!</strong>
            </p>
            <p className="text-black-50">
              When you get notifications,they'll show up here.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
