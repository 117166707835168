import React from "react";
import { PageLayout } from "layouts";
import SessionExpired from "assets/images/assessment/session-expired.svg";
import { useNavigate, useLocation } from "react-router-dom";

export const ExpiredPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const message = location?.state?.message;

  const goToDashboard = () => {
    navigate("/home/assessment", { replace: true });
  };

  return (
    <PageLayout>
      <div className="row">
        <div className="col-12">
          <div className="expired-page pt-5 pb-5">
            <div className="expired-page-wrap m-auto">
              <img src={SessionExpired} alt="icon" />
              <h3>Your session has expired !</h3>
              <p>{message}</p>
              <button onClick={goToDashboard} className="result-page-btn">
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
