import { Axios } from "utils";

export const getCourses = async (type, studentBatchId) => {
  const res = await Axios.get(
    `/v2/portal/programs/batches/${studentBatchId}/courses/${type}`
  );
  return res.data;
};

export const getCoursesById = async (programId, courseId) => {
  const res = await Axios.get(
    `/V2/portal/programs/${programId}/courses/${courseId}/details`
  );
  return res.data;
};

export const getTaskDetailsById = async (courseTaskId) => {
  const res = await Axios.get(`/v2/portal/courseTasks/${courseTaskId}/details`);
  return res.data;
};

export const generateSimulationToken = async (taskId, refreshToken) => {
  const res = await Axios.get(
    `/CourseTasks/GenerateSimulationToken/${taskId}?refreshToken=${refreshToken}`
  );
  return res.data;
};

export const updateCourseTaskStatus = async ({ studentTaskId, taskStatus }) => {
  const res = await Axios.put(
    `/v2/portal/courseTasks/${studentTaskId}/update/${taskStatus}`
  );
  return res.data;
};

export const getStudentSpecificData = async (programId, courseId) => {
  const res = await Axios.get(
    `/v2/portal/Programs/${programId}/courses/${courseId}/student-specific-data`
  );
  return res.data;
};
