import { CertificateList } from "..";
import { Routes, Route } from "react-router-dom";
import "./styles/certificate.styles.css";

export const Certificates = ({ showAll = false }) => {
  return (
    <Routes>
      <Route path="/" element={<CertificateList showAll={showAll} />} />
    </Routes>
  );
};
