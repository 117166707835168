import React from "react";
import { CourseList, CourseDetails, CourseTasks } from "..";
import { Routes, Route } from "react-router-dom";
import "./styles/courses.styles.css";

export const Courses = () => {
  return (
    <Routes>
      <Route path="/:courseType" element={<CourseList />} />
      <Route path="/:courseType/:courseId" element={<CourseDetails />} />
      <Route path="/tasks" element={<CourseTasks />} />
    </Routes>
  );
};
