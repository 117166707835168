import parse from "html-react-parser";
import { CourseContent } from "..";

export const CourseSection = (props) => {
  const { title, description } = props.courseSection;

  return (
    <div className="card mb-4">
      <div className="card-header" style={{ backgroundColor: "white" }}>
        <div className="col-12 pt-3 pb-3">
          <h5 className="mb-0 text-dark">{title}</h5>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div>{parse(description)}</div>
            <div className="d-flex justifycontent-start flex-wrap"></div>
          </div>
        </div>
        <div className="col-12">
          <div className="d-flex justify-content-start flex-wrap">
            {props.courseSection.courseContents.map((content, index) => {
              return (
                <CourseContent
                  key={`CourseContent${index}`}
                  content={content}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
