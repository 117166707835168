import React, { useCallback } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { QuestionModalItem } from "..";
import { useQuestionareContext } from "master";

export const QuestionsModal = () => {
  const questionnaireContext = useQuestionareContext();
  const {
    questionnaire,
    currentQuestionIndex,
    isModalOpen,
    setIsModalOpen,
    onChangeSection,
    setCurrentQuestionIndex,
    getFilteredQuestions,
    markedQuestions,
    getCurrentSectionIndex,
    getCurrentQuestionIndex,
  } = questionnaireContext;

  const currentSectionQuestions = getFilteredQuestions(
    currentQuestionIndex.sectionIndex
  );

  const onCancel = useCallback(() => {
    setIsModalOpen((draft) => {
      draft = {
        type: "",
        isOpen: false,
      };
      return draft;
    });
  }, [isModalOpen]);

  const navigateQuestionHandle = useCallback(
    (questionNumber, sectionId) => {
      const sectionIndex = getCurrentSectionIndex(sectionId);
      const questionIndex = getCurrentQuestionIndex(questionNumber);
      setCurrentQuestionIndex((draft) => {
        draft = {
          sectionIndex,
          questionIndex,
        };
        return draft;
      });
      setIsModalOpen((draft) => {
        draft = {
          type: "",
          isOpen: false,
        };
        return draft;
      });
    },
    [currentQuestionIndex, isModalOpen]
  );

  return (
    <Modal
      isOpen={isModalOpen.type === "allQuestions" && isModalOpen.isOpen}
      toggle={onCancel}
      size={"xl"}
      className={"question-preview-modal"}
      scrollable={true}
    >
      <ModalHeader toggle={onCancel}>{"All Questions"}</ModalHeader>
      <ModalBody>
        <div className="questions-preview-modal">
          <div className="page-header-btn-group">
            {questionnaire &&
              questionnaire?.sections?.map((section, index) => (
                <button
                  key={section.sectionId}
                  onClick={() => onChangeSection(section.sectionId)}
                  className={`assessment-md-btn assessment-header-btn shadow-none ${
                    currentQuestionIndex.sectionIndex === index ? "active" : ""
                  }`}
                >
                  {section?.name}
                </button>
              ))}
          </div>
          <div className="questions-preview-list">
            {currentSectionQuestions &&
              currentSectionQuestions.map((question, index) => {
                return (
                  <QuestionModalItem
                    key={question?.questionNumber}
                    question={question ? question : []}
                    index={index}
                    navigateQuestionHandle={navigateQuestionHandle}
                    markedQuestions={markedQuestions}
                  />
                );
              })}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
