import React from "react";
import moment from "moment";

export const ResultCard = ({ assessmentResult, goToDashboard }) => {
  const {
    assessmentName,
    maximumScore,
    obtainedScore,
    startedAt,
    endedAt,
    timeTaken,
    assessmentSchedule,
    message,
    resultStatus,
    resultStatusStr,
  } = assessmentResult;

  const isPassedAssessment = resultStatus === 4;
  const isAvailableScore =
    obtainedScore >= 0 &&
    typeof obtainedScore === "number" &&
    maximumScore >= 0 &&
    typeof maximumScore === "number";

  return (
    <div className="assessment-result-card">
      <div
        className={`result-card-header ${
          isPassedAssessment ? "result-passed" : "result-failed"
        }`}
      >
        <div className="result-card-content">
          <div
            className={`result-card-icon ${
              isPassedAssessment ? "result-passed-icon" : "result-failed-icon"
            }`}
          ></div>
          <h3>{isPassedAssessment ? "Congratulations" : "Failed"}</h3>
          <p className="card-main-text">
            You {resultStatusStr}{" "}
            <span className="b-txt">{assessmentName}</span> on{" "}
            {moment(assessmentSchedule).format("DD MMM YYYY")} {startedAt} -{" "}
            {endedAt}
          </p>
          {message && <p className="card-sub-text">{message}</p>}
        </div>
      </div>
      <div className="result-card-bottom-content">
        {isAvailableScore && (
          <div className="result-card-row px-4 py-3 d-flex justify-content-between align-items-center">
            <span className="card-row-label">Your Score</span>
            <span className="card-row-bold-txt">
              {obtainedScore}/{maximumScore}
            </span>
          </div>
        )}
        {timeTaken && (
          <div className="result-card-row px-4 py-3 d-flex justify-content-between align-items-center">
            <span className="card-row-label">Time Taken</span>
            <span className="card-row-bold-txt">{timeTaken}</span>
          </div>
        )}
        <div className="result-card-row text-center p-4">
          <button onClick={goToDashboard} className="result-page-btn">
            Go to dashboard
          </button>
        </div>
      </div>
    </div>
  );
};
