import PdfIcon from "assets/images/pdf-icon.svg";
import { Buffer } from "buffer";

export const PdfContent = ({ title, redirectTo }) => {
  return (
    <a
      className="chip-button border ps-3 pe-3 pt-2 pb-2 me-3 mb-3 d-flex rounded-pill align-items-center"
      href={`/home/content/${redirectTo}`}
      target="_blank"
      rel="noreferrer"
    >
      <img src={PdfIcon} alt="icon" className="me-2" />
      <span>{title}</span>
    </a>
  );
};
