import { Link } from "react-router-dom";
import RoundGreenTick from "assets/images/green-round-tick.svg";
import { WelcomeBanner } from "..";

export function PasswordGenerationMessage({ title, description }) {
  return (
    <div>
      <div id="auth">
        <div className="row h-100">
          <WelcomeBanner />
          <div className="col-lg-6 col-12 d-flex">
            <div
              id="auth-right"
              className="auth-right-block d-flex align-items-center"
            >
              <div className="auth-right-form">
                <h4 className="auth-title text-center">Create Password</h4>
                <img
                  className="auth-img mx-auto d-block mb-3 mt-3"
                  src={RoundGreenTick}
                />
                <h4 className="auth-title text-center">{title}</h4>
                <p className="auth-subtitle mb-3 text-center">{description}</p>
                <Link to="/">
                  <button className="btn btn-block btn-lg shadow-lg ni-btn-primary">
                    Go to Login Page
                  </button>
                </Link>
              </div>
              <div className="auth-right-footer">
                <p className="auth-copyright-txt">&copy; NERGY INDIA PVT LTD</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
