import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export const AnswerSubmitConfirmModal = ({
  isOpen,
  onCancel,
  size,
  title,
  children,
  onSubmit,
  isSubmittingAnswers,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      size={size}
      {...rest}
      style={{ width: "470px" }}
    >
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="assessment-modal-layout review-modal">
              <div className="modal-md-icon info-md-icon"></div>
              <p>
                <b>Are you sure to submit your test</b>
              </p>
              <div className="modal-btn-group">
                <button
                  onClick={onSubmit}
                  disabled={isSubmittingAnswers}
                  className="assessment-md-btn px-5 tile-btn-blue"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
