import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Input, FormFeedback, Spinner } from "reactstrap";
import { useForgotPassword, WelcomeBanner } from "..";
import SimpleReactValidator from "simple-react-validator";

export function ForgotPassword() {
  const { forgotPasswordAuth, setForgotPassEmail, forgotPassEmail } =
    useForgotPassword();
  const { mutate, isLoading } = forgotPasswordAuth;
  const { email } = forgotPassEmail;
  const [update, forceUpdate] = useState();

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const onsubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      mutate(email);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div>
      <div id="auth">
        <div className="row h-100">
          <WelcomeBanner />
          <div className="col-lg-6 col-12 d-flex">
            <div
              id="auth-right"
              className="auth-right-block d-flex align-items-center"
            >
              <div className="auth-right-form">
                <div className="auth-back-btn mb-5">
                  <Link to="/" className="back-btn">
                    <i className="bi bi-arrow-left"></i>
                    <span>Back</span>
                  </Link>
                </div>
                <h4 className="auth-title text-center">
                  Forgot Your Password?
                </h4>
                <p className="auth-subtitle mb-3 text-center">
                  Enter Your Email and We will send you a link to reset
                  password.
                </p>
                <form>
                  <div className="form-group position-relative mb-4">
                    <label className="mb-1">Email Address</label>
                    <Input
                      type="email"
                      name="email"
                      className="form-control form-control-lg"
                      disabled={isLoading}
                      value={email}
                      onChange={(e) => {
                        setForgotPassEmail((draft) => {
                          draft.email = e.target.value;
                        });
                      }}
                      invalid={validator.current.message(
                        "email",
                        email,
                        "required|email"
                      )}
                    />
                    <FormFeedback>
                      {validator.current.message(
                        "email",
                        email,
                        "required|email"
                      )}
                    </FormFeedback>
                  </div>
                  <button
                    className="btn btn-block btn-lg shadow-lg mt-1 ni-btn-primary"
                    disabled={isLoading}
                    onClick={(e) => {
                      onsubmit(e);
                    }}
                  >
                    {isLoading ? (
                      <Spinner type="border" color="light" />
                    ) : (
                      "Send"
                    )}
                  </button>
                </form>
              </div>
              <div className="auth-right-footer">
                <p className="auth-copyright-txt">&copy; NERGY INDIA PVT LTD</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
