import { useImmer } from "use-immer";
import { authenticate, signOutAccount } from "..";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store";
import { errorFormatter } from "shared";
import { setCookie, removeCookie } from "utils";
import { COOKIE_EXPIRY_DAYS } from "master";

export const useAuthenticate = () => {
  const navigate = useNavigate();
  const { setAppState, isRemember } = useAppStore();
  const [authenticateState, setAuthenticateState] = useImmer({
    credential: { userName: "", password: "" },
    isValidate: false,
    isLoading: false,
  });

  const mutation = useMutation(authenticate, {
    onSuccess: (data) => {
      setAuthenticateState((draft) => {
        draft.isValidate = true;
        return draft;
      });
      if (data.token) {
        setAppState((draft) => data);
        const { refreshToken, token, user } = data;
        const { permissions, ...rest } = user;
        const userData = {
          refreshToken,
          token,
          user: { ...rest },
        };

        if (isRemember) {
          setCookie(
            "_stu_user_data",
            JSON.stringify(userData),
            COOKIE_EXPIRY_DAYS
          );
        } else {
          setCookie("_stu_user_data", JSON.stringify(userData));
        }
        navigate("/home", { replace: true });
      }
    },
    onError: (data) => {
      errorFormatter(data);
    },
    onSettled: () => {
      setAuthenticateState((draft) => {
        draft.isLoading = false;
        return draft;
      });
    },
  });

  const signoutAccount = useMutation(signOutAccount, {
    onSuccess: () => {
      removeCookie("_stu_user_data");
      localStorage.removeItem("selectedProgram");
      localStorage.removeItem("answeredQuestions");
      window.location.href = "/";
    },
    onError: (e) => {
      errorFormatter(e);
    },
  });

  const onUserSignOut = () => {
    signoutAccount.mutate();
  };

  return {
    authenticateState,
    setAuthenticateState,
    mutation,
    signoutAccount,
    onUserSignOut,
  };
};
