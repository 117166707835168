import { useState, useRef, useEffect, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Input, FormFeedback } from "reactstrap";
import { useSupportTicket } from "..";
import SimpleReactValidator from "simple-react-validator";
import ControlledTextarea from "shared/components/ControlledTextarea";

export const SupportTicketModal = ({ size, isOpen, title, onCancel }) => {
  const [update, forceUpdate] = useState();
  const {
    ticketValues,
    setTicketValues,
    supportTickets,
    resetSupportTicketForm,
  } = useSupportTicket();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        mobile: {
          message: "Phone number must be 10 digits.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(
                val,
                /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
              ) && params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    })
  );

  useEffect(() => {
    resetSupportTicketForm();
    validator.current.hideMessages();
  }, [isOpen]);

  const onHandleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (
        (name === "registrationId" || name === "name" || name === "email") &&
        value.length > 50
      )
        return;
      setTicketValues((draft) => {
        draft[name] = value;
        return draft;
      });
    },
    [ticketValues]
  );

  const onHandleTextarea = useCallback(
    (e) => {
      const { name, value } = e;
      setTicketValues((draft) => {
        draft[name] = value;
        return draft;
      });
    },
    [ticketValues]
  );

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      const { name, registrationId, email, phonenumber, ...rest } =
        ticketValues;
      const subjectContent = `My name is ${name} (registration ID - ${registrationId}) and my contact details  - Email: ${email} & Phonenumber: ${
        phonenumber ? phonenumber : "N/A"
      }`;
      supportTickets.mutate({
        ...rest,
        subject: subjectContent,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} size={size} centered={true}>
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <div className="row support-ticket-form">
          <div className="col-12">
            <p className="sm-title text-center">
              Enter your details and remarks
            </p>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <label className="mb-2" htmlFor="first-name-vertical">
                Enter Your Name*
              </label>
              <Input
                type="text"
                id="first-name-vertical"
                className="form-control"
                name="name"
                placeholder="Name"
                value={ticketValues.name}
                onChange={onHandleChange}
                invalid={validator.current.message(
                  "name",
                  ticketValues.name,
                  "required"
                )}
              />
              <FormFeedback>
                {validator.current.message(
                  "name",
                  ticketValues.name,
                  "required"
                )}
              </FormFeedback>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <label className="mb-2" htmlFor="first-registrationId-vertical">
                Enter Registration ID*
              </label>
              <Input
                type="text"
                id="first-registrationId-vertical"
                className="form-control"
                name="registrationId"
                placeholder="Registration ID"
                value={ticketValues.registrationId}
                onChange={onHandleChange}
                invalid={validator.current.message(
                  "registrationId",
                  ticketValues.registrationId,
                  "required"
                )}
              />
              <FormFeedback>
                {validator.current.message(
                  "registrationId",
                  ticketValues.registrationId,
                  "required"
                )}
              </FormFeedback>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <label className="mb-2" htmlFor="first-email-vertical">
                Enter Your Email ID*
              </label>
              <Input
                type="text"
                id="first-email-vertical"
                className="form-control"
                name="email"
                placeholder="Email ID"
                value={ticketValues.email}
                onChange={onHandleChange}
                invalid={validator.current.message(
                  "email",
                  ticketValues.email,
                  "required|email"
                )}
              />
              <FormFeedback>
                {validator.current.message(
                  "email",
                  ticketValues.email,
                  "required|email"
                )}
              </FormFeedback>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <label className="mb-2" htmlFor="first-phonenumber-vertical">
                Enter Your Phone Number
              </label>
              <Input
                type="text"
                id="first-phonenumber-vertical"
                className="form-control"
                name="phonenumber"
                placeholder="Phone Number"
                value={ticketValues.phonenumber}
                onChange={onHandleChange}
                invalid={validator.current.message(
                  "phonenumber",
                  ticketValues.phonenumber,
                  "numeric|mobile"
                )}
              />
              <FormFeedback>
                {validator.current.message(
                  "phonenumber",
                  ticketValues.phonenumber,
                  "numeric|mobile"
                )}
              </FormFeedback>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <ControlledTextarea
                placeholder="Your message"
                id="first-message-vertical"
                label="Enter Your Issue"
                name="message"
                value={ticketValues.message}
                rows="3"
                limit={150}
                ref={validator}
                isValidationRequired={true}
                onChange={onHandleTextarea}
              />
            </div>
          </div>
          <div className="support-ticket-footer">
            <Button className="support-ticket-cancel" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="ni-btn-primary support-ticket-submit"
              color="primary"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
