import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

export const StudentDataModal = ({
  isOpen,
  studentSpecificData,
  onConfirm,
  onCloseModal,
  studentDataDisplayName,
}) => {
  return (
    <Modal
      className="stu-data-modal"
      isOpen={isOpen}
      centered={true}
      toggle={onCloseModal}
    >
      <ModalHeader toggle={onCloseModal}>{studentDataDisplayName}</ModalHeader>
      <ModalBody>
        <div className="data-rows">
          {studentSpecificData ? (
            studentSpecificData.map((element, index) => {
              return (
                <>
                  {Object.entries(element).map(([key, value]) => (
                    <div className="data-row" key={`${key}_${index}`}>
                      <span>{key}</span>
                      <span>{value}</span>
                    </div>
                  ))}
                  {index !== studentSpecificData.length - 1 && <hr />}
                </>
              );
            })
          ) : (
            <span>No data found!</span>
          )}
        </div>
        <div className="student-modal-action">
          <Button
            color="primary"
            onClick={onConfirm}
            className="student-info-modal-btn"
          >
            Ok
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
