import React, { useCallback, useMemo } from "react";
import parse from "html-react-parser";
import { MediaPreviewModal } from "shared";

export const QuestionItem = ({
  question,
  onMarkQuestion,
  onAnswer,
  onClearAnswer,
  markedQuestions,
  mediaPreviewState,
  answeringQuestion,
  clearAnswer,
}) => {
  const {
    description,
    choices,
    attachments,
    answer,
    questionId,
    sectionId,
    questionNumber,
  } = question;

  const { toggleModal, onSetUrl, modalState } = mediaPreviewState;

  const isNotAnswered = answer === null;
  const isClearingAnswer = clearAnswer?.isLoading;
  const isAnswering = answeringQuestion?.isLoading;

  const onMediaClick = useCallback(
    (url, mediaType) => {
      if (url) {
        onSetUrl({ url, mediaType });
      }
    },
    [attachments]
  );

  const formattedChoices = useMemo(() => {
    return (
      choices?.length > 0 &&
      [...choices]?.sort((b, a) => {
        if (a?.code < b?.code) return 1;
        if (a?.code > b?.code) return -1;
        return 0;
      })
    );
  }, [choices]);

  const renderMediaByType = useCallback(
    (url, type) => {
      switch (type) {
        case 0:
          return url ? (
            <>
              <div className="attachment-overlay">
                <i className="bi bi-eye"></i>
              </div>
              <img src={url} alt="media" />
            </>
          ) : (
            <span className="no-attachment-msg">No media found</span>
          );
        case 1:
          return url ? (
            <>
              <div className="attachment-overlay">
                <i className="bi bi-play-circle"></i>
              </div>
              <video width="100%" height="auto" controls={false}>
                <source src={`${url}#t=0.2`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          ) : (
            <span className="no-attachment-msg">No media found</span>
          );
        default:
          return null;
      }
    },
    [attachments]
  );

  const isMarked =
    markedQuestions &&
    markedQuestions?.some(
      (c) => c.questionId === questionId && c.sectionId === sectionId
    );

  return (
    <>
      <div className="assessment-question-item">
        <div className="assessment-question">
          <div className="d-flex align-items-start">
            <span className="question-number">{`(${questionNumber}).`}</span>
            {description && parse(description)}
          </div>
          <div className="question-attachment-group mt-4 mb-4">
            {attachments &&
              attachments?.length > 0 &&
              attachments?.map((attachment) => {
                const {
                  questionAttachmentName,
                  questionAttachmentsUrls,
                  contentType,
                } = attachment;
                return (
                  <div
                    key={questionAttachmentName}
                    onClick={() =>
                      onMediaClick(questionAttachmentsUrls, contentType)
                    }
                    className="question-attachment"
                  >
                    {renderMediaByType(questionAttachmentsUrls, contentType)}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="assessment-question-choices">
          <ul className="assessment-question-choice-list">
            {formattedChoices &&
              formattedChoices?.map((q) => {
                return (
                  <li key={q.choiceId} className="assessment-question-choice">
                    <div className="custom-radio-button">
                      <input
                        type="radio"
                        id={`choice-${q?.choiceId}`}
                        name={`choice_${q?.questionId}`}
                        value={q.choiceId}
                        checked={q.choiceId === answer?.choiceId}
                        disabled={isAnswering}
                        onChange={() =>
                          onAnswer({
                            questionId: q?.questionId,
                            choiceId: q?.choiceId,
                            sectionId,
                          })
                        }
                      />
                      <label htmlFor={`choice-${q?.choiceId}`}>
                        {`${q?.code}). ${q?.description}`}
                      </label>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="question-actions">
          <button
            disabled={isNotAnswered || isClearingAnswer || isAnswering}
            onClick={() => onClearAnswer(answer?.id, questionId, sectionId)}
            className="question-action-btn"
          >
            <span className="question-action-icon icon-close"></span>
            <span>Clear</span>
          </button>
          <button
            onClick={() => onMarkQuestion({ questionId, sectionId })}
            className={`question-action-btn ${isMarked ? "marked" : ""}`}
          >
            <span className="question-action-icon icon-mark"></span>
            <span>{isMarked ? "Unmark" : "Mark for Review"}</span>
          </button>
        </div>
      </div>
      <MediaPreviewModal
        isOpen={modalState.isModalOpen}
        url={modalState.url}
        mediaType={modalState.mediaType}
        onCancel={() => toggleModal()}
      />
    </>
  );
};
