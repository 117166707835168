import React, { useEffect } from "react";
import { DetailsPageLayout } from "layouts";
import { CourseSection, CourseTasks, useCourses, StudentDataModal } from "..";
import { useParams, useNavigate } from "react-router-dom";
import { trimText } from "utils";
import { LoadingSpinner, EmptyMessage } from "shared";
import { useImmer } from "use-immer";
import DefaultThumbnail from "assets/images/default-vidya-thumbnail-image.svg";

export const CourseDetails = () => {
  let { courseId, courseType } = useParams();
  const navigate = useNavigate();
  let selectedProgram = JSON.parse(localStorage.getItem("selectedProgram"));
  const [studentSpecificData, setStudentSpecificData] = useImmer(null);

  const {
    courseDetails,
    selectedCourseType,
    currentCourseName,
    taskControl,
    courseInfo,
    onSetSelectedTask,
    currentTask,
    onNextTask,
    onPrevTask,
    onStartSimulation,
    taskDetailsInfo,
    updateCourseTasksStatus,
    modalState,
    onOpenModal,
    onCloseModal,
    studentSpecificDataQuery,
  } = useCourses({
    programId: selectedProgram?.id,
    studentBatchId: selectedProgram.studentBatchId,
    type: courseType,
    courseId,
    load: false,
  });
  const isRegular = courseDetails?.courseType === 0;
  const hasTask = !isRegular && courseDetails?.hasTasks;
  const hasSection = !isRegular && !courseDetails?.hasTasks;
  const hasTasksAssigned = courseInfo.data && !courseInfo.isError;
  const hasTasksNotAssigned = courseInfo?.data?.length === 0;
  const studentDataDisplayName = courseDetails?.studentDataDisplayName;

  const thumbnail = courseDetails?.courseImageURL
    ? courseDetails?.courseImageURL
    : DefaultThumbnail;

  const { isOpen, actionFrom } = modalState;

  useEffect(() => {
    if (studentSpecificDataQuery?.data) {
      setStudentSpecificData(
        studentSpecificDataQuery?.data
          ? studentSpecificDataQuery.data.map((item) => {
              if (item.endsWith(",}")) {
                const element = item.replace(/,(?=[^,]*$)/, "");
                return JSON.parse(element);
              } else {
                return JSON.parse(item);
              }
            })
          : null
      );
    }
  }, [studentSpecificDataQuery?.data]);

  if (courseInfo.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {hasTasksAssigned && (
        <DetailsPageLayout
          title={courseDetails?.name}
          description={courseDetails?.description}
          image={thumbnail}
          breadcrumb={[
            {
              label: selectedProgram.name,
              location: `/home`,
            },
            {
              label: selectedCourseType?.title,
              location: `/home/courses/${selectedCourseType?.type.toLowerCase()}`,
            },
            { label: trimText(currentCourseName) },
          ]}
          overallPercentage={courseDetails?.overallPercentage}
          totalDuration={courseDetails?.totalDuration}
          courseType={courseDetails?.courseType}
          studentDataDisplayName={studentDataDisplayName}
          onOpenModal={onOpenModal}
        >
          {!hasTask && courseDetails?.courseSections?.length > 0 ? (
            courseDetails?.courseSections.map((section, index) => {
              return (
                section.isEnabled && (
                  <CourseSection
                    key={`CourseSection_${index}`}
                    courseSection={section}
                  />
                )
              );
            })
          ) : (
            <>
              {(isRegular || hasSection) && (
                <div>
                  <EmptyMessage
                    heading={"No sections added to this course!"}
                    subHeading={`There is no sections added for this course.`}
                    image={selectedCourseType?.icon}
                    onCancel={() =>
                      navigate(
                        `/home/courses/${selectedCourseType?.type.toLowerCase()}`
                      )
                    }
                  />
                </div>
              )}
            </>
          )}
          {hasTask && (
            <CourseTasks
              tasks={courseDetails?.selectedstudentTasks}
              courseType={courseDetails?.courseType}
              taskControl={taskControl}
              onSetSelectedTask={onSetSelectedTask}
              currentTask={currentTask}
              onNextTask={onNextTask}
              onPrevTask={onPrevTask}
              onStartSimulation={onStartSimulation}
              taskDetailsInfo={taskDetailsInfo}
              updateCourseTasksStatus={updateCourseTasksStatus}
            />
          )}
        </DetailsPageLayout>
      )}
      <div>
        {hasTasksNotAssigned && (
          <EmptyMessage
            heading={"No tasks assigned to this course!"}
            subHeading={`There is no tasks assigned for you at the moment.`}
            image={selectedCourseType?.icon}
            onCancel={() =>
              navigate(
                `/home/courses/${selectedCourseType?.type.toLowerCase()}`
              )
            }
          />
        )}
      </div>

      {isOpen && actionFrom === "student_specific_data" && (
        <StudentDataModal
          isOpen={isOpen && actionFrom === "student_specific_data"}
          studentDataDisplayName={studentDataDisplayName}
          studentSpecificData={studentSpecificData}
          onConfirm={onCloseModal}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};
