import React from "react";
import { useQuery } from "react-query";
import { assessmentResult, GET_ASSESSMENT_RESULT } from "..";

export const useAssessmentResultQuery = ({ assessmentMasterId }) => {
  return useQuery(
    [GET_ASSESSMENT_RESULT, assessmentMasterId],
    () => assessmentResult(assessmentMasterId),
    {
      enabled: !!assessmentMasterId,
    }
  );
};
