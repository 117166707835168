import React, { useCallback } from "react";

export const AttemptItem = ({ attempt }) => {
  const {
    referenceNo,
    scheduledOn,
    startedAtStr,
    endedAtStr,
    assessmentMasterStatus,
    startAt,
    endAt,
  } = attempt;

  const isAbsent = assessmentMasterStatus === 8;

  const status = useCallback(() => {
    switch (assessmentMasterStatus) {
      case 0:
        return {
          color: "teal",
          text: "In progress",
        };
      case 1:
        return {
          color: "green",
          text: "Completed",
        };
      case 2:
        return {
          color: "orange",
          text: "Invalid",
        };
      case 3:
        return {
          color: "yellow",
          text: "Not started",
        };
      case 4:
        return {
          color: "green",
          text: "Passed",
        };
      case 5:
        return {
          color: "red",
          text: "Failed",
        };
      case 6:
        return {
          color: "blue",
          text: "Awaiting result",
        };
      case 7:
        return {
          color: "blue",
          text: "Not completed",
        };
      case 8:
        return {
          color: "blue",
          text: "Absent",
        };
      case 9:
        return {
          color: "yellow",
          text: "Upcoming",
        };
      default:
        return {
          color: "",
          text: "",
        };
    }
  }, [assessmentMasterStatus]);

  const statusIndicator = status();
  const scheduledOnText = `${scheduledOn} ${startAt ? startAt : ""} - ${
    endAt ? endAt : ""
  }`;

  return (
    <div className="attempt-list d-flex">
      <div className="attempt-cols attempt-col-a">
        <span className="attempt-label">Reference No</span>
        <span className="attempt-text">
          {!isAbsent && referenceNo ? referenceNo : "_ _"}
        </span>
      </div>
      <div className="attempt-cols attempt-col-b">
        <span className="attempt-label">Scheduled On</span>
        <span className="attempt-text">{scheduledOnText}</span>
      </div>
      <div className="attempt-cols attempt-col-c">
        <span className="attempt-label">Started:</span>
        <span className="attempt-text">
          {!isAbsent && startedAtStr ? startedAtStr : "_ _"}
        </span>
      </div>
      <div className="attempt-cols attempt-col-d">
        <span className="attempt-label">Ended</span>
        <span className="attempt-text">
          {!isAbsent && endedAtStr ? endedAtStr : "_ _"}
        </span>
      </div>
      <div className="attempt-cols attempt-col-e d-flex align-items-center">
        <span
          className={`attempt-status-text attempt-status-text-${statusIndicator.color}`}
        >
          {statusIndicator.text}
        </span>
      </div>
    </div>
  );
};
