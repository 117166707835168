import { useCallback } from "react";
import { useQuery, useMutation } from "react-query";
import {
  GET_CERTIFICATES,
  GET_ALL_CERTIFICATES,
  getCertificates,
  getAllCertificates,
  getCertificateById,
} from "..";
import { useImmer } from "use-immer";
import { trimText } from "utils";
import moment from "moment";

export const useCertificate = ({ load = false, studentBatchId = null }) => {
  const [certificateModalState, setCertificateModalState] = useImmer({
    isOpen: false,
    title: "",
    certificationDate: "",
    studentCertificateId: "",
    url: "",
  });

  const certificatesQuery = useQuery(
    [GET_CERTIFICATES, studentBatchId],
    () => getCertificates(studentBatchId),
    {
      enabled: load && !!studentBatchId,
    }
  );

  const allCertificatesQuery = useQuery(
    [GET_ALL_CERTIFICATES],
    getAllCertificates,
    {
      enabled: load && !studentBatchId,
    }
  );

  const getCertificate = useMutation(getCertificateById);

  const formateDate = (value) => {
    return value
      ? moment(value, "DD-MMM-YYYY hh:mm:ss A").format("DD MMM YYYY hh:mm A")
      : "";
  };

  const onToggleModal = useCallback(() => {
    setCertificateModalState((draft) => {
      draft.isOpen = !draft.isOpen;
      draft.certificateUrl = "";
      draft.certificationDate = "";
      draft.studentCertificateId = "";
      draft.title = "";
      return draft;
    });
  }, [certificateModalState]);

  const downloadPdf = async (url = "", certificateName) => {
    if (url) {
      let c_name = trimText(certificateName, 30, "");
      const fileName = c_name.split(" ").join("_");
      await fetch(url).then((response) => {
        response.blob().then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${fileName}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
      });
    }
  };

  const certificateAction = async (
    id,
    actionFrom = "download",
    title,
    localCreatedDate
  ) => {
    getCertificate.mutate(id);
    const { data: certificateUrl } = getCertificate;

    if (actionFrom === "download") {
      await downloadPdf(certificateUrl, title);
    } else {
      setCertificateModalState((draft) => {
        draft.isOpen = true;
        draft.studentCertificateId = id;
        draft.certificationDate = localCreatedDate;
        draft.title = title;
        return draft;
      });
    }
  };

  return {
    certificateModalState,
    onToggleModal,
    downloadPdf,
    certificatesQuery,
    formateDate,
    certificateAction,
    getCertificate,
    allCertificatesQuery,
    setCertificateModalState,
  };
};
