import LinkIcon from "assets/images/link-icon.svg";

export const ExternalLinkContent = ({ label, redirectTo }) => {
  return (
    <a
      className="chip-button border ps-3 pe-3 pt-2 pb-2 me-3 mb-3 d-flex rounded-pill align-items-center"
      href={redirectTo}
      target="_blank"
    >
      <img src={LinkIcon} alt="icon" className="me-2" />
      <span>{label}</span>
    </a>
  );
};
