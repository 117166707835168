import React, { useCallback } from "react";
import EmptyAvatar from "assets/images/avatar-empty.png";

export const Avatar = ({ userName }) => {
  const userNameText = userName ? userName : [];

  const splitUserName = useCallback(
    (user) => {
      if (user && user.length > 0) {
        const firstName = user[0] || "";
        const lastName = user[1] || "";

        const firstLetter = firstName[0];
        const lastLetter = lastName ? lastName[0] : "";

        if (!lastName) {
          return <span>{firstLetter}</span>;
        } else {
          return (
            <span>
              {firstLetter}
              {lastLetter}
            </span>
          );
        }
      } else {
        return <img src={EmptyAvatar} alt="user" />;
      }
    },
    [userNameText]
  );

  return (
    <div className="profile-user-avatar border rounded-circle d-flex justify-content-center align-items-center ms-auto me-auto mb-5 overflow-hidden">
      {splitUserName(userNameText)}
    </div>
  );
};
