import React, { useEffect } from "react";
import { PageLayout } from "layouts";
import {
  QuestionItem,
  QuestionsModal,
  QuestionStatusBadge,
  QuestionLegend,
} from "..";
import { useNavigate } from "react-router-dom";
import { useQuestionareContext } from "master";
import { LoadingSpinner } from "shared";

export const Questionnaire = () => {
  const navigate = useNavigate();
  const questionnaireContext = useQuestionareContext();
  const {
    questionnaire,
    markedQuestions,
    currentQuestionIndex,
    onChangeSection,
    currentQuestion,
    onAnswer,
    onClearAnswer,
    onNext,
    onPrevious,
    onMarkQuestion,
    setIsModalOpen,
    startAssessmentQuery,
    onChangeSidebarButton,
    disableBackButton,
    mediaPreviewState,
    answeringQuestion,
    clearAnswer,
  } = questionnaireContext;

  useEffect(() => {
    disableBackButton();
  }, []);

  const isLastSection =
    questionnaire?.sections?.length - 1 === currentQuestionIndex?.sectionIndex;
  const isLastQuestion =
    questionnaire?.questions?.length - 1 ===
    currentQuestionIndex?.questionIndex;
  const isFirstSection = currentQuestionIndex?.sectionIndex === 0;
  const isFirstQuestion = currentQuestionIndex?.questionIndex === 0;

  if (startAssessmentQuery?.isLoading) return <LoadingSpinner />;

  return (
    <div>
      <PageLayout>
        <div className="row">
          <div className="col-sm-8">
            <div className="assessment-page-header">
              <div className="page-header-btn-group">
                {questionnaire &&
                  questionnaire?.sections?.map((section, index) => (
                    <button
                      key={section.sectionId}
                      onClick={() => onChangeSection(section.sectionId)}
                      className={`assessment-md-btn assessment-header-btn shadow-none ${
                        currentQuestionIndex.sectionIndex === index
                          ? "active"
                          : ""
                      }`}
                    >
                      {section?.name}
                    </button>
                  ))}
              </div>
            </div>
            <div className="assesment-question-wrapper">
              {currentQuestion && (
                <QuestionItem
                  question={currentQuestion}
                  onMarkQuestion={onMarkQuestion}
                  onAnswer={onAnswer}
                  onClearAnswer={onClearAnswer}
                  markedQuestions={markedQuestions}
                  mediaPreviewState={mediaPreviewState}
                  answeringQuestion={answeringQuestion}
                  clearAnswer={clearAnswer}
                />
              )}
            </div>
            <div className="footer-question-navgation">
              <button
                onClick={onPrevious}
                className="assessment-md-btn question-nav-btn shadow-none"
                disabled={isFirstSection && isFirstQuestion}
              >
                <span className="question-action-icon  icon-previous"></span>
                <span>Previous</span>
              </button>
              <button
                onClick={onNext}
                className="assessment-md-btn question-nav-btn shadow-none"
                disabled={isLastSection && isLastQuestion}
              >
                <span>Next</span>
                <span className="question-action-icon  icon-next"></span>
              </button>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="questions-info-block">
              <div className="questions-sidebar">
                <div className="sidebar-header">
                  <div className="sidebar-header-item">
                    <div className="sidebar-header-btn">
                      <span>
                        {`Questions (${questionnaire?.questions?.length ?? 0})`}
                      </span>
                    </div>
                  </div>
                  <div className="sidebar-header-item d-flex justify-content-end">
                    <button
                      onClick={() =>
                        setIsModalOpen((draft) => {
                          draft = {
                            type: "allQuestions",
                            isOpen: true,
                          };
                          return draft;
                        })
                      }
                      className="sidebar-header-btn all-questions-btn"
                    >
                      <span className="side-header-icon icon-lists"></span>
                      <span>All Questions</span>
                    </button>
                  </div>
                </div>
                <div className="sidebar-body">
                  <div className="questions-dot-navigation">
                    {questionnaire &&
                      questionnaire?.questions?.map((question, index) => {
                        return (
                          <QuestionStatusBadge
                            key={question.questionId}
                            index={index}
                            question={question}
                            markedQuestions={markedQuestions}
                            currentQuestionIndex={currentQuestionIndex}
                            onSelect={() => {
                              onChangeSidebarButton(question.sectionId, index);
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="question-nav-footer">
                  <button
                    onClick={() => navigate("/home/assessment/review")}
                    className="sidebar-md-btn"
                  >
                    Review / Submit
                  </button>
                  <div className="questions-legends">
                    <QuestionLegend type="toAttend" />
                    <QuestionLegend type="current" />
                    <QuestionLegend type="marked" />
                    <QuestionLegend type="completed" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <QuestionsModal />
      </PageLayout>
    </div>
  );
};
