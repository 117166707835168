export const QuestionStatusBadge = ({
  question,
  index,
  markedQuestions,
  currentQuestionIndex,
  onSelect,
}) => {
  const { questionId, sectionId, answer, choices, questionNumber } = question;

  const isCurrentQuestion =
    currentQuestionIndex && currentQuestionIndex?.questionIndex === index;

  const isMarked =
    markedQuestions &&
    markedQuestions?.some(
      (c) => c.questionId === questionId && c.sectionId === sectionId
    );

  const isAnswered =
    answer != null && choices?.some((c) => c.choiceId === answer.choiceId);

  return (
    <button
      onClick={onSelect ? onSelect : () => {}}
      key={questionId}
      className={`dot-nav ${isCurrentQuestion ? "current" : ""} ${
        isMarked ? "marked" : ""
      } ${isAnswered ? "checked" : ""}`}
    >
      <span>{questionNumber}</span>
      {(isMarked || isAnswered) && <span className="question-status"></span>}
    </button>
  );
};
