import React from "react";
import ResultIcon from "assets/images/assessment/result-md-icon.svg";

export const PublishSoonCard = ({ message, goToDashboard }) => {
  return (
    <div className="publish-soon-wrap">
      <img src={ResultIcon} alt="icon" />
      <p>{message}</p>
      <button onClick={goToDashboard} className="result-page-btn">
        Go to dashboard
      </button>
    </div>
  );
};
