import React, { useEffect, useRef, useState } from "react";

export const TasksSidebar = (props) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const currentButtonRef = useRef(null);

  const { tasks, onSetSelectedTask, courseType, taskControl } = props;
  const isOjt = courseType === 2;

  useEffect(() => {
    if (currentButtonRef.current) {
      currentButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: firstLoad ? "center" : "start",
      });
      if (firstLoad) {
        setFirstLoad(false);
      }
    }
  }, [currentButtonRef, taskControl.currentIndex]);

  return (
    <div className="tasks-sidebar col-md-3 p-0 me-md-4 me-sm-0">
      <div className="task-sidebar-header  py-3 px-3">
        <h3>Tasks</h3>
      </div>
      <ul className="tasks-list-menu">
        {tasks &&
          tasks.map((task, index) => {
            const isCompleted = task.studentTaskStatus === 2;
            const isCurrent = index === taskControl.currentIndex;
            const buttonClass = isCurrent ? "current" : "";
            const buttonStatus = isCompleted ? "task-icon-completed" : "";

            return (
              <li key={task.courseTaskId} className="task-list-item">
                <button
                  onClick={() => onSetSelectedTask(index)}
                  ref={isCurrent ? currentButtonRef : null}
                  className={`tasks-link ${buttonStatus} ${buttonClass}`}
                >
                  <span className="task-list-link-icon"></span>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>
                      {index + 1}. {task.title}
                    </span>
                    {isOjt && (
                      <div className="ps-2">{task.taskDuration}&nbsp;Min</div>
                    )}
                  </div>
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
