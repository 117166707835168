import { useState } from "react";
import { Input } from "reactstrap";

export const PasswordInput = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <>
      <Input type={passwordShown ? "text" : "password"} {...props} />
      <div
        className="passwordTglBtn"
        onClick={() => setPasswordShown(!passwordShown)}
      >
        <i
          className={`bi ${
            passwordShown ? "bi-eye-slash-fill" : "bi-eye-fill"
          }`}
          id="togglePassword"
        ></i>
      </div>
    </>
  );
};
