import React from "react";
import { useQuery } from "react-query";
import { getProgramHelps } from "..";

const GET_PROGRAM_HELP = "GET_PROGRAM_HELP";

export const useProgramHelp = ({ load = false, programId = null }) => {
  const programHelpQuery = useQuery(
    GET_PROGRAM_HELP,
    () => getProgramHelps(programId),
    {
      enabled: load && !!programId,
    }
  );

  return {
    programHelpQuery,
  };
};
