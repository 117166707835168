import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useAppStore } from "store";
import { Input, FormFeedback } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { WelcomeBanner, useAuthenticate } from "..";
import appInfo from "../../../package.json";

export const Login = () => {
  const { authenticateState, setAuthenticateState, mutation } =
    useAuthenticate();
  const { userName, password } = authenticateState.credential;
  const { AppState, setIsRemember, isRemember } = useAppStore();
  const navigate = useNavigate();
  const [update, forceUpdate] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const onsubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      setAuthenticateState((draft) => {
        draft.isLoading = true;
        return draft;
      });
      mutation.mutate(authenticateState.credential);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    if (AppState.token) return navigate("/home");
  }, [AppState.token]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setAuthenticateState((draft) => {
      draft.credential[name] = value;
    });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleIsRemember = () => {
    setIsRemember((draft) => {
      draft = !draft;
      return draft;
    });
  };

  return (
    <div>
      <div id="auth">
        <div className="row h-100">
          <WelcomeBanner />
          <div className="col-lg-6 col-12 d-flex">
            <div
              id="auth-right"
              className="auth-right-block d-flex align-items-center"
            >
              <div className="auth-right-form">
                <h4 className="auth-title text-center">Login</h4>
                <p className="auth-subtitle mb-3 text-center">
                  Please Login Your Account
                </p>
                <form>
                  <div className="form-group position-relative mb-4">
                    <label className="mb-1">Email Address</label>
                    <Input
                      type="text"
                      id="auth-email-vertical"
                      name="userName"
                      className="form-control form-control-lg"
                      value={userName}
                      disabled={authenticateState.isLoading}
                      onChange={onHandleChange}
                      invalid={validator.current.message(
                        "username",
                        userName,
                        "required|email"
                      )}
                    />
                    <FormFeedback>
                      {validator.current.message(
                        "username",
                        userName,
                        "required|email"
                      )}
                    </FormFeedback>
                  </div>
                  <div className="form-group position-relative mb-4 has-icon-right">
                    <label className="mb-1">Password</label>
                    <Input
                      type={passwordShown ? "text" : "password"}
                      id="auth-password-vertical"
                      name="password"
                      className="form-control form-control-lg"
                      disabled={authenticateState.isLoading}
                      value={password}
                      onChange={onHandleChange}
                      invalid={validator.current.message(
                        "password",
                        password,
                        "required"
                      )}
                    />
                    <div className="passwordTglBtn" onClick={togglePassword}>
                      <i
                        className={`bi ${
                          passwordShown ? "bi-eye-slash-fill" : "bi-eye-fill"
                        }`}
                        id="togglePassword"
                      ></i>
                    </div>
                    <FormFeedback>
                      {validator.current.message(
                        "password",
                        password,
                        "required"
                      )}
                    </FormFeedback>
                  </div>
                  <div className="col-12 text-center mb-4">
                    <p style={{ fontSize: "18px" }}>
                      Don’t have a password?
                      <Link to="/generatePassword">
                        <strong> Generate Now</strong>
                      </Link>
                    </p>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="form-check form-check-inline">
                      <label htmlFor="first-remember-vertical">
                        Remember Me
                      </label>
                      <Input
                        type="checkbox"
                        id="first-remember-vertical"
                        className="form-check-input"
                        name="remember"
                        onChange={toggleIsRemember}
                        checked={isRemember}
                      />
                    </div>
                    <Link to="/forgotPassword">Forgot Password?</Link>
                  </div>
                  <button
                    className="btn btn-block btn-lg shadow-lg mt-4 ni-btn-primary"
                    disabled={authenticateState.isLoading}
                    onClick={(e) => {
                      onsubmit(e);
                    }}
                  >
                    {authenticateState.isLoading ? (
                      <Spinner type="border" color="light" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </form>
              </div>

              <div className="auth-right-footer">
                <p className="auth-copyright-txt">
                  <small>{appInfo.version}</small> &copy; NERGY INDIA PVT LTD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
