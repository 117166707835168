import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import parse from "html-react-parser";

export const InstructionsModal = ({
  isOpen,
  onCancel,
  onSubmit,
  data,
  actionFrom,
  ...rest
}) => {
  const { name, noOfQuestions, maxTime, maxAttempt, instructions } = data;

  const isFromAssessmentList = actionFrom === "assessmentList";

  return (
    <Modal isOpen={isOpen} toggle={onCancel} size={"lg"} {...rest}>
      <ModalHeader toggle={onCancel}>Instructions</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="assessment-modal-infos">
              <div className="modal-header-block">
                <h3>{name}</h3>
                <div className="d-flex align-iems-center info-sm-items">
                  <span>Max Attempts: {maxAttempt}</span> {"|"}
                  <span>Total Questions: {noOfQuestions}</span> {"|"}
                  <span>Total Time: {maxTime} min</span>
                </div>
              </div>
              <div className="modal-info-content">
                <h3>
                  Read the below instructions carefully before you start the
                  exam:
                </h3>
                {instructions && parse(instructions)}
              </div>
              {isFromAssessmentList && (
                <div className="modal-btn-group text-end">
                  <button
                    onClick={onSubmit}
                    className="assessment-md-btn px-4 tile-btn-blue"
                  >
                    Proceed
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
