import { PageLayout } from "layouts";
import { CertificateItem, useCertificate, CertificatePreviewModal } from "..";
import { EmptyMessage, LoadingSpinner } from "shared";
import defaultCertificate from "assets/images/nergy-certificate-demo.png";
import sapCertificate from "assets/images/SAP_Certificate_2023.png";
import CertificateEmptyImage from "assets/images/certificates-empty-screen.svg";
import { useNavigate } from "react-router-dom";

export const CertificateList = ({ showAll }) => {
  const navigate = useNavigate();
  const isShowAll = showAll;
  const selectedProgram = isShowAll
    ? false
    : JSON.parse(localStorage.getItem("selectedProgram"));
  const studentBatchId = selectedProgram?.studentBatchId;

  const {
    getCertificate,
    certificatesQuery,
    allCertificatesQuery,
    certificateModalState,
    downloadPdf,
    onToggleModal,
    formateDate,
    certificateAction,
    setCertificateModalState,
  } = useCertificate({ load: true, studentBatchId });

  const { data: certificateList, isLoading } = isShowAll
    ? allCertificatesQuery
    : certificatesQuery;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout
      title={
        isShowAll
          ? "Certificate"
          : certificateList?.length > 0
          ? "My Certifications"
          : ""
      }
      breadcrumb={[
        {
          label: selectedProgram?.name ? selectedProgram?.name : "Home",
          location: "/home",
        },
        { label: isShowAll ? "Certificate" : "My Certifications" },
      ]}
    >
      <div className="row mb-5 g-3">
        {certificateList?.length > 0 ? (
          certificateList
            .sort((a, b) => b.isGenerated - a.isGenerated)
            .map((certificate) => {
              return (
                <CertificateItem
                  key={certificate.certificateName}
                  certificate={{
                    ...certificate,
                    image:
                      certificate?.templateName ===
                      "EFiling_Certificate_2022_v2"
                        ? defaultCertificate
                        : sapCertificate,
                  }}
                  formateDate={formateDate}
                  certificateAction={certificateAction}
                  getCertificate={getCertificate}
                  setCertificateModalState={setCertificateModalState}
                  downloadPdf={downloadPdf}
                />
              );
            })
        ) : (
          <div className="col-12">
            <EmptyMessage
              heading={"No Certificates found"}
              subHeading={
                <span>
                  There is no certificates found at this moment.
                  <br />
                  Please check back later.
                </span>
              }
              image={CertificateEmptyImage}
              onCancel={() => navigate(`/home`)}
            />
          </div>
        )}
      </div>
      <CertificatePreviewModal
        isOpen={certificateModalState.isOpen}
        onCancel={onToggleModal}
        downloadPdf={downloadPdf}
        getCertificate={getCertificate}
        certificateModalState={certificateModalState}
      />
    </PageLayout>
  );
};
