import React, { useEffect, useState } from "react";
import { AttemptItem, useTimeout } from "..";
import moment from "moment";

export const AssessmentItem = ({
  assessmentData,
  onStartAssessment,
  onScheduleAssessment,
  isRefetching,
  isFetching,
}) => {
  const {
    name,
    noOfQuestions,
    maxTime,
    totalMark,
    remainingAttempt,
    maxAttempt,
    attemptStatus,
    activeSchedule,
    assessmentId,
    attempts,
    slotId,
    attemptId,
    instructions,
    currentSystemTime,
    startAt,
    scheduleMasterId,
    endAt,
  } = assessmentData;

  const [isTimeUp, setIsTimeUp] = useState(false);

  const { timer } = useTimeout({
    currentTime: currentSystemTime,
    startTime: startAt,
  });

  const isSchedule = attemptStatus === 0;
  const isScheduled = attemptStatus === 1;
  const isStart = attemptStatus === 2;
  const isContinue = attemptStatus === 3;
  const isNotAttempted = attemptStatus === 4;
  const isPassed = attemptStatus === 5;
  const isFailed = attemptStatus === 6;
  const isAwaitingResult = attemptStatus === 7;

  useEffect(() => {
    if (timer <= 0 && isScheduled) {
      setIsTimeUp(true);
    } else {
      setIsTimeUp(false);
    }
  }, [timer]);

  const formattedScheduled = (activeSchedule, startAt, endAt) => {
    const scheduleDate = moment(activeSchedule).format("DD-MMM-YYYY");
    const startTime = moment(startAt).format("hh:mm A");
    const endTime = moment(endAt).format("hh:mm A");
    return `Scheduled on ${scheduleDate} at ${startTime}-${endTime}`;
  };

  return (
    <div className="assessment-list-item card rounded-3">
      <div className="d-flex justify-content-start align-items-center pt-3 pb-3 px-4">
        <div className="col-sm-8">
          <h3 className="assessment-title">{name}</h3>
          <div className="d-flex justify-content-start align-items-center mt-2">
            <div className="assessment-tile-sm-items me-3 d-flex justify-content-start align-items-center">
              <span className="tile-sm-icon tile-icon-questions"></span>
              <span className="tile-sm-text">{noOfQuestions} Questions</span>
            </div>
            <div className="assessment-tile-sm-items me-3 d-flex justify-content-start align-items-center">
              <span className="tile-sm-icon tile-icon-time"></span>
              <span className="tile-sm-text">{maxTime} Min</span>
            </div>
            <div className="assessment-tile-sm-items me-3">
              <span className="tile-sm-badge">
                You have {remainingAttempt}/{maxAttempt} attempts left
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          {isRefetching || isFetching ? (
            <div className="assessment-tile-actions text-end">
              <svg
                className="assessment-sm-loader"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 0 0"
              >
                <circle fill="#999" stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="0.8s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1"
                  />
                </circle>
                <circle fill="#999" stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="0.8s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2"
                  />
                </circle>
                <circle fill="#999" stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="0.8s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3"
                  />
                </circle>
              </svg>
            </div>
          ) : (
            <div className="assessment-tile-actions text-end">
              {isSchedule && (
                <button
                  onClick={() =>
                    onScheduleAssessment({ assessmentId, scheduleMasterId })
                  }
                  className="assessment-md-btn tile-btn-blue"
                >
                  Schedule Assessment
                </button>
              )}
              {isScheduled && !isTimeUp && (
                <div className="assessment-status-info-text">
                  <p>{formattedScheduled(activeSchedule, startAt, endAt)}</p>
                </div>
              )}
              {(isStart || (isTimeUp && isScheduled)) && (
                <button
                  onClick={() =>
                    onStartAssessment({
                      assessmentId,
                      slotId,
                      attemptId,
                      noOfQuestions,
                      maxTime,
                      totalMark,
                      maxAttempt,
                      instructions,
                      name,
                      attemptStatus,
                      isStartAssessment: true,
                    })
                  }
                  className="assessment-md-btn tile-btn-green"
                >
                  Start Assessment
                </button>
              )}
              {isContinue && (
                <button
                  onClick={() =>
                    onStartAssessment({
                      assessmentId,
                      slotId,
                      attemptId,
                      noOfQuestions,
                      maxTime,
                      totalMark,
                      maxAttempt,
                      instructions,
                      name,
                      attemptStatus,
                      isStartAssessment: false,
                    })
                  }
                  className="assessment-md-btn tile-btn-yellow"
                >
                  Continue Assessment
                </button>
              )}
              {isNotAttempted && (
                <span className="assessment-status-badge-md assessment-status-not-attempted">
                  Not Attempted
                </span>
              )}
              {isPassed && (
                <span className="assessment-status-badge-md assessment-status-passed">
                  Assessment Passed
                </span>
              )}
              {isFailed && (
                <span className="assessment-status-badge-md assessment-status-failed">
                  Failed
                </span>
              )}
              {isAwaitingResult && (
                <span className="assessment-status-badge-md assessment-status-awaiting">
                  Result Awaiting
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {attempts && attempts.length > 0 && (
        <div className="attempts-list-wrap pt-3 pb-3 px-4">
          {attempts.map((attempt, index) => {
            return (
              <AttemptItem
                key={`attempt-item-${attempt?.slotId}-${index}`}
                attempt={attempt}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
